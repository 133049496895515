import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import LoginPage from '../pages/Others/LoginPage';
import { AuthContext } from '../helpers/authContext';



const PublicRoutes = ({ children }) => {

  const { user, loading } = useContext(AuthContext);

  if (loading) return <div className='spinner'>Cargando...</div>;
  if (user) {

    return user.id_rol === "1" ? <Navigate to="/Docentes" /> : <Navigate to="/Estudiantes" />;
  }

  return children;

};

export default PublicRoutes