import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";


import { GiHamburgerMenu, GiNotebook } from "react-icons/gi";
import { FaClipboardCheck } from "react-icons/fa6";
import { CiLogout } from "react-icons/ci";
import { urlLogout } from "../../helpers/endpoints";
import api from "../../helpers/Api";

const NavbarStudent = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const [open, setOpen] = useState(true);


    const userD = JSON.parse(localStorage.getItem("user")) || null;


    const codigoUsuario = userD?.id_usuario || "";
    const primerNombre = userD?.p_nombre || "";
    const primerAppellido = userD?.p_apellido || "";

    const primeraLetra = [primerNombre.charAt(0), primerAppellido.charAt(0)];

    const logout = async () => {
        try {
            const response = await api.post(`${urlLogout}`);

            navigate('/');
            localStorage.removeItem('user');
        }
        catch (error) {

            navigate('/');
            localStorage.removeItem('user');
        }
    }





    return (
        <div className="flex">

            <div className={`bg-[#735FF1] min-h-screen py-5 pl-5 pt-8 ${open ? "w-72" : "w-20"} duration-300 relative`}>
                <div className='p-1 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200 absolute right-4 top-8' onClick={() => setOpen(!open)}>
                    <GiHamburgerMenu className={`text-3xl rounded-full`} />
                </div>


                <Link to="/Estudiantes">
                    <div className={`w-20 sm:w-2/3 lg:w-full pb-8 bg-transparent flex items-center justify-between`}>
                        <h1 className={`flex text-2xl sm:text-3xl font-extrabold text-white ${!open && "hidden"}`}>Sacsi Chiyo</h1>
                    </div>
                </Link>



                <div className={`bg-transparent flex items-center ${!open && "pt-10"}`}>
                    <div className='bg-[#FFE65B] rounded-lg mr-2'>
                        <div className='text-[#735FF1] px-3 py-2 font-semibold'>{primeraLetra}</div>
                    </div>
                    <div className={`${!open && "hidden"}`}>
                        <h1 className={`sm:text-xl font-medium text-white`}>{primerNombre} {primerAppellido}</h1>
                        <h1 className={`text-sm text-white`}>Tzolom</h1>
                    </div>
                </div>


                <Link to="/Estudiantes" state={{ codigoUsuario }}>
                    <div className={location.pathname === '/Estudiantes' ? 'rounded-full bg-indigo-800' : ''}>
                        <div className={`text-gray-50 flex items-center gap-x-4 cursor-pointer p-2 hover:rounded-full hover:bg-indigo-300 hover:text-violet-700 mt-2 `}>
                            <span className='text-2xl block float-left'>
                                <GiNotebook />
                            </span>
                            <span className={`font-medium flex-1 ${!open && "hidden"}`}>
                                xkab' Tzolom
                            </span>
                        </div>
                    </div>
                </Link>


                <Link to="/Estudiantes/TemasAdicionales" state={{ codigoUsuario }}>
                    <div className={location.pathname === '/Estudiantes/TemasAdicionales' ? 'rounded-full bg-indigo-800' : ''}>
                        <div className={`text-gray-50 flex items-center gap-x-4 cursor-pointer p-2 hover:rounded-full hover:bg-indigo-300 hover:text-violet-700 rounded-md mt-2`}>
                            <span className='text-2xl block float-left'>
                                <FaClipboardCheck />
                            </span>
                            <span className={`font-medium flex-1 ${!open && "hidden"}`}>
                                Xtz'aqob' Nawom
                            </span>
                        </div>
                    </div>
                </Link>


                <div className={`text-gray-50 flex items-center gap-x-4 cursor-pointer p-2 mt-10 hover:bg-red-200 hover:text-red-700 rounded-md`} onClick={logout}>
                    <span className='text-2xl block float-left'>
                        <CiLogout />
                    </span>
                    <span className={`font-medium flex-1 ${!open && "hidden"}`}>
                        Tz'apok Rakal
                    </span>
                </div>


            </div>
            <main className='min-h-screen w-full'>
                <Outlet />
            </main>
        </div>
    )
}
export default NavbarStudent;