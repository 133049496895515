import React from 'react'
import { Information } from '../../../components/Student/Default/Information'

function InformationPage() {
  return (
    <div className="bg-[#f3f4f6] h-full">
      <Information />
    </div>
  )
}

export default InformationPage