import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { urlLista_Leccion_Modificar, urlModificarLecciones, urlTiposLecciones } from '../../../helpers/endpoints';
import api from '../../../helpers/Api';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { IoIosArrowBack } from 'react-icons/io';

function UpdateLesson({
    setActivo,
    updateLeccion
}) {
    const location = useLocation();

    const user_D = JSON.parse(localStorage.getItem("user")) || null;

    const Id_Docente = user_D.id_usuario || location?.state?.codigoUsuario;

    const [hoverOut, setHoverOut] = useState(false);

    const [datosAModificar, setDatosAModificar] = useState({});
    const [tipoLeccion, setTipoLeccion] = useState([]);


    const [formData, setFormData] = useState({
        IdGrado: '',
        IdTema: '',
        IdTipoLeccion: '',
        IdLeccion: '',
        NombreLeccion: '',
        Informacion: '',
        IdDocente: '',
        EjemplosU: [
            {
                IdEjemploU: '',
                EjemploU: '',
                EjemploTraduccionU: ''
            }
        ],
    });

    const [missingFields, setMissingFields] = useState([]);


    //Drop down list para tipo de lecciones
    useEffect(() => {
        const getTiposLecciones = async () => {
            try {
                const responseTiposLecciones = await api.get(`${urlTiposLecciones}`);
                setTipoLeccion(responseTiposLecciones.data);


            } catch (error) {
                console.log(error);
            }
        };
        getTiposLecciones();
    }, []);

    const handleChangeDDLTiposLecciones = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setFormData({
            ...formData,
            [name]: value,
        });
    };




    useEffect(() => {
        const getDatosModificaciones = async () => {
            try {
                const responseDatos = await api.get(`${urlLista_Leccion_Modificar}?IdDocente=${updateLeccion.IdDocente}&IdGrado=${updateLeccion.IdGrado}&IdTema=${updateLeccion.IdTema}&IdLeccion=${updateLeccion.IdLeccion}`);
                setDatosAModificar(responseDatos.data);

                setFormData({
                    IdGrado: responseDatos.data.id_tema_tema.temas_x_grados[0].id_grado || '',
                    IdTema: responseDatos.data.id_tema_tema.id_tema || '',
                    IdTipoLeccion: responseDatos.data.id_tipo_leccion || '',
                    IdLeccion: responseDatos.data.id_leccion || '',
                    NombreLeccion: responseDatos.data.nombre_leccion || '',
                    Informacion: responseDatos.data.informacion || '',
                    IdDocente: Id_Docente || updateLeccion.IdDocente,
                    EjemplosU: responseDatos.data.ejemplos || ''
                });

            } catch (error) {
                console.log(error);
            }
        };
        getDatosModificaciones();
    }, [Id_Docente, updateLeccion])


    const onResetLesson = () => {
        setActivo(false);
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleExampleChange = (index, fleldName, value) => {
        const updatedExamples = [...formData.EjemplosU];


        updatedExamples[index] = {
            ...updatedExamples[index],
            [fleldName]: value
        };

        setFormData({
            ...formData,
            EjemplosU: updatedExamples
        })
    };




    const handleModificarClick = async (e) => {
        e.preventDefault();

        const missingFieldsArray = [];

        if (!formData.IdGrado) missingFieldsArray.push('IdGrado');
        if (!formData.IdTema) missingFieldsArray.push('IdTema');
        if (!formData.IdTipoLeccion) missingFieldsArray.push('IdTipoLeccion');
        if (!formData.NombreLeccion) missingFieldsArray.push('NombreLeccion');
        if (!formData.Informacion) missingFieldsArray.push('Informacion');
        if (!formData.IdDocente) missingFieldsArray.push('IdDocente');

        if (!formData.EjemplosU.every((example) => example.EjemploU)) missingFieldsArray.push('EjemploU');
        if (!formData.EjemplosU.every((example) => example.EjemploTraduccionU)) missingFieldsArray.push('EjemploTraduccionU');


        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return;
        }

        try {
            const response = await api.put(`${urlModificarLecciones}`, formData);
            setDatosAModificar(response.data);

            setFormData({
                IdGrado: response.data.id_tema_tema.temas_x_grados[0].id_grado || '',
                IdTema: response.data.id_tema_tema.id_tema || '',
                IdTipoLeccion: response.data.id_tipo_leccion || '',
                IdLeccion: response.data.id_leccion || '',
                NombreLeccion: response.data.nombre_leccion || '',
                Informacion: response.data.informacion || '',
                IdDocente: Id_Docente || updateLeccion.IdDocente,
                EjemplosU: response.data.ejemplos || ''
            });

            Swal.fire('Modificación exitosa', 'La lección se ha modificado correctamente', 'success');
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al modificar la lección. Por favor, inténtelo nuevamente.'
            });
        }

    };





    return (
        <>
            <div className="w-10 py-2 lg:py-5" onMouseEnter={() => setHoverOut(true)} onMouseLeave={() => { setHoverOut(false) }}>
                <button className="flex justify-center items-center font-bols border border-gray-500 p-1 rounded-lg transition-all text-gray-500 hover:p-2 hover:bg-red-400 hover:border-white hover:text-white" onClick={onResetLesson}>
                    <IoIosArrowBack className='font-bold text-lg' />
                    <h3 className={`text-sm ${hoverOut === false ? 'hidden' : 'block'}`}>Abandonar</h3>
                </button>
            </div>

            <div className='flex justify-center pb-5'>
                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Modificar Lecciones</h1>
            </div>

            <form className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6' onSubmit={handleModificarClick}>
                <div>
                    <label className='text-sm sm:text-base font-medium'>Grado: </label>
                    <input
                        className={`w-full p-2 mt-2 text-sm sm:text-base bg-gray-100 border-2 border-gray-400 rounded text-slate-500 cursor-not-allowed
                                ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                        type="text"
                        name="nombre_grado"
                        value={datosAModificar?.id_tema_tema?.temas_x_grados[0].id_grado_grado?.nombre_grado || ''}
                        // value={datosAModificar.nombre_leccion || 'Vacío'}
                        readOnly
                    />
                </div>
                <div>
                    <label className='text-sm sm:text-base font-medium'>Tema: </label>
                    <input
                        className={`w-full p-2 mt-2 text-sm sm:text-base bg-gray-100 border-2 border-gray-400 rounded text-slate-500 cursor-not-allowed
                                ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                        type="text"
                        name="nombre_tema"
                        value={datosAModificar?.id_tema_tema?.nombre_tema || ''}
                        readOnly
                    />
                </div>



                <div className=''>
                    <label className="text-sm sm:text-base font-medium">Tipo de Lección: </label>
                    <Select
                        options={tipoLeccion}
                        className={`w-full mt-2 text-sm sm:text-base border-2 border-indigo-500 rounded
                                    ${missingFields.includes('IdTipoLeccion') ? 'border-red-500' : ''}`}
                        classNamePrefix="select"
                        isSearchable={false}
                        name='IdTipoLeccion'
                        placeholder="Seleccione el Tipo de Lección"
                        onChange={handleChangeDDLTiposLecciones}
                        value={tipoLeccion.find(tipoL => tipoL.value === formData.IdTipoLeccion) || null}
                    />
                </div>



                <div className=''>
                    <label className='text-sm sm:text-base font-medium'>Lección: </label>
                    <input
                        className={`w-full p-2 mt-2 text-sm sm:text-base border-2 border-indigo-500 rounded text-slate-500
                                ${missingFields.includes('NombreLeccion') ? 'border-red-500' : ''}`}
                        type="text"
                        name="NombreLeccion"
                        value={formData.NombreLeccion || ''}
                        onChange={handleInputChange}
                    />
                </div>


                <div className='lg:col-span-2'>
                    <label className='text-sm sm:text-base font-medium'>Información: </label>
                    <textarea
                        className={`w-full p-2 mt-2 text-sm sm:text-base border-2 border-indigo-500 rounded text-slate-500
                                ${missingFields.includes('Informacion') ? 'border-red-500' : ''}`}
                        name="Informacion"
                        value={formData.Informacion || ''}
                        onChange={handleInputChange}
                    />
                </div>


                <div className='mb-10 lg:mb-0 px-2 pt-2 pb-4 lg:px-4 lg:pt-4 lg:pb-6 lg:col-span-2 rounded-xl bg-sky-300'>
                    <div className="mb-2">
                        <label className='text-sm font-medium sm:text-base'>Ejemplos: </label>
                    </div>

                    {Array.isArray(formData?.EjemplosU) && formData.EjemplosU.map((example, index) => (
                        <div key={example.IdEjemploU} className='mb-4 px-1 py-2 lg:p-4 grid grid-cols-1 gap-4 lg:grid-cols-12 lg:gap-6 rounded-xl bg-orange-200'>
                            <label className='text-sm font-medium sm:text-base lg:col-span-2'>Ejemplo en Español: </label>
                            <input
                                className={`w-full p-2 text-sm sm:text-base h-full lg:mt-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                        ${missingFields.includes('EjemploU') ? 'border-red-500' : ''}`}
                                type="text"
                                name="EjemploU"
                                value={example?.EjemploU || ''}
                                onChange={(e) => handleExampleChange(index, 'EjemploU', e.target.value)}
                            />
                            <label className='text-sm font-medium sm:text-base lg:col-span-2'>Ejemplo en Q’eqchi’: </label>
                            <input
                                className={`w-full p-2 text-sm sm:text-base h-full lg:mb-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                        ${missingFields.includes('EjemploTraduccionU') ? 'border-red-500' : ''}`}
                                type="text"
                                name="EjemploTraduccionU"
                                value={example?.EjemploTraduccionU || ''}
                                onChange={(e) => handleExampleChange(index, 'EjemploTraduccionU', e.target.value)}
                            />
                        </div>
                    ))}


                </div>

                <div className='lg:col-span-2 mb-5 sm:mt-5 sm:mb-10'>
                    <button className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-1 rounded-xl bg-green-500 text-white text-lg font-bold px-4 hover:bg-green-600' type="submit">
                        Modificar
                    </button>
                </div>
            </form>

        </>
    )
}

export default UpdateLesson