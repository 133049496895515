import React, { useEffect, useState } from 'react'
import { ResultsAdditional } from '../ResultsAdditional';
import { IoIosArrowBack } from "react-icons/io";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";


export const QuizAdditional = ({
    preguntasFinales,
    setIndexQuestion,
    indexQuestion,
    preguntas,
    setActiveQuiz,

    IdEstudiante,
    IdGrado,
    IdTema,
    IdLeccion,
    QTiempo,
    QPuntaje,
    QTotal,
    QTiempoUso
}) => {
    const [score, setScore] = useState(0);
    const [selectAnswerIndex, setSelectAnswerIndex] = useState(null);
    const [answered, setAnswered] = useState(false);
    const [activeResults, setActiveResults] = useState(false)
    const [answerRandom, setAnswerRandom] = useState([]);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [hoverOut, setHoverOut] = useState(false);
    const [questionStatus, setQuestionStatus] = useState(Array(preguntas.length).fill(null));

    useEffect(() => {
        setStartTime(new Date().toISOString());
    }, []);

    useEffect(() => {
        const answers = [...preguntasFinales.respuesta && preguntasFinales.respuesta.map((resp) => resp)];

        setAnswerRandom(answers.sort(() => Math.random() - 0.5));
    }, [preguntasFinales]);


    const handleFinalizar = () => {
        setEndTime(new Date().toISOString());
    };


    const checkAnswer = (answerText, index) => {
        const isCorrect = answerText.respuestaa === preguntasFinales.respuesta.find((resp) => resp.correcta === '1').respuestaa;
        if (isCorrect) {
            setScore(score + 1);

        }
        setSelectAnswerIndex(index);
        setAnswered(true);

        const updatedQuestionStatus = [...questionStatus];
        updatedQuestionStatus[indexQuestion] = isCorrect ? 'correct' : 'incorrect';
        setQuestionStatus(updatedQuestionStatus);
    };


    const onNextQuestion = () => {
        setIndexQuestion(indexQuestion + 1);
        setSelectAnswerIndex(null);
        setAnswered(false);
    };

    const onReset = () => {
        setScore(0);
        setActiveQuiz(false);
        setIndexQuestion(0);
        setStartTime(null);
        setEndTime(null);
    };

    return (
        <div className='bg-gray-200 h-full'>
            {
                activeResults ?
                    (
                        <ResultsAdditional
                            preguntas={preguntas}
                            score={score}
                            onReset={onReset}
                            Inicio={startTime}
                            Final={endTime}

                            Id_Estudiante={IdEstudiante}
                            Id_Grado={IdGrado}
                            Id_Tema={IdTema}
                            Id_Leccion={IdLeccion}
                            Q_Tiempo={QTiempo}
                            Q_Puntaje={QPuntaje}
                            Q_Total={QTotal}
                            Q_TiempoUso={QTiempoUso}
                        />
                    ) :
                    (
                        <>
                            <div className='h-full mx-auto max-w-2xl px-2 py-2 sm:p-6 lg:max-w-7xl lg:px-8 bg-blue-300'>
                                <div className="w-10 mb-5" onMouseEnter={() => setHoverOut(true)} onMouseLeave={() => { setHoverOut(false) }}>
                                    <button className="flex justify-center items-center font-bols border border-gray-500 p-1 rounded-lg transition-all text-gray-500 hover:p-2 hover:bg-red-400 hover:border-white hover:text-white" onClick={onReset}>
                                        <IoIosArrowBack className='font-bold text-lg' />
                                        <h3 className={`text-sm ${hoverOut === false ? 'hidden' : 'block'}`}>Abandonar</h3>
                                    </button>
                                </div>

                                <div className='my-5'>
                                    <h1 className='text-center font-comforter text-lg sm:text-2xl md:text-3xl text-blue-900'>{preguntasFinales.id_leccion_leccione.nombre_leccion}</h1>
                                </div>

                                <div className='flex flex-wrap justify-center items-start gap-2 sm:gap-5 my-5'>
                                    {preguntas && preguntas.map((preg, index) => (
                                        <div key={preg.id_pregunta} className={`w-10 aspect-[4/4] rounded-full flex flex-col justify-center items-center`}>
                                            {questionStatus[index] === 'correct' ? (
                                                <div className='w-full h-full rounded-full border-2 border-green-500 flex flex-col justify-center items-center bg-indigo-200'>
                                                    <RiCheckLine className='text-2xl text-green-500' />
                                                </div>
                                            ) :
                                                questionStatus[index] === 'incorrect' ? (
                                                    <div className='w-full h-full rounded-full border-2 border-red-500 flex flex-col justify-center items-center bg-indigo-200'>
                                                        <RiCloseLine className='text-2xl text-red-500' />
                                                    </div>
                                                ) :
                                                    (
                                                        <div className='w-full h-full rounded-full border-2 border-indigo-500 flex flex-col justify-center items-center bg-indigo-200'>
                                                            <div className='w-full h-full flex items-center justify-center text-2xl font-semibold text-indigo-500'>
                                                                {index + 1}
                                                            </div>
                                                        </div>
                                                    )}
                                        </div>
                                    ))}
                                </div>



                                {/* <div className='flex flex-wrap justify-center sm:justify-between md:justify-around items-start gap-2 my-5 sm:my-8'>
                            <div className={`w-10 aspect-[4/4] rounded-full border-2 border-red-500 flex flex-col justify-center items-center bg-indigo-200`}>
                            <RiCloseLine className='w-full h-full text-red-500' />
                            </div>
                            <div className={`w-10 aspect-[4/4] rounded-full border-2 border-green-500 flex flex-col justify-center items-center bg-indigo-200`}>
                            <RiCheckLine  className='w-full h-full text-green-500' />
                            </div>
                            <div className={`w-10 aspect-[4/4] rounded-full border-2 border-red-500 flex flex-col justify-center items-center bg-indigo-200`}>
                            <RiCloseLine className='w-full h-full text-red-500' />
                            </div>
                            <div className={`w-10 aspect-[4/4] rounded-full border-2 border-indigo-500 flex flex-col justify-center items-center bg-indigo-200`}>
                            <div className='w-full h-full flex items-center justify-center text-2xl font-semibold text-indigo-500'>
                                4
                            </div>
                            </div>
                            <div className={`w-10 aspect-[4/4] rounded-full border-2 border-indigo-500 flex flex-col justify-center items-center bg-indigo-200`}>
                            <div className='w-full h-full flex items-center justify-center text-2xl font-semibold text-indigo-500'>
                                5
                            </div>
                            </div>
                        </div> */}



                                {/* <div className="bg-sky-300 flex flex-col justify-center items-center">
                            <h2 className="font-normal text-xl">Puntuación: {score}</h2>
                            <h2 className="font-medium">{indexQuestion +1}/{preguntas.length}</h2>
                        </div> */}
                                <div className='flex justify-center py-2 mb-2 md:my-5'>
                                    <h3 className='font-semibold text-sm sm:text-lg md:text-xl'>{preguntasFinales.pregunta}</h3>
                                </div>

                                <div className='w-full lg:px-20'>
                                    <div className='grid grid-cols-1 rounded-xl gap-2 px-2 py-4 bg-gray-100 md:grid-cols-2 md:gap-4 md:px-4 md:py-8'>
                                        {
                                            answerRandom.map((answer, index) => (
                                                <button
                                                    className={`p-5 border-2 rounded-xl flex justify-center items-center hover:scale-105 hover:bg-gray-200 hover:border-indigo-500
                                    ${selectAnswerIndex !== null && index === selectAnswerIndex ?
                                                            answer.respuestaa === preguntasFinales.respuesta.find((resp) => resp.correcta === '1').respuestaa ?
                                                                'bg-green-200 border-green-500' :
                                                                'bg-red-200 border-red-500' :
                                                            ''
                                                        }
                                    ${answered && selectAnswerIndex !== index ? 'cursor-not-allowed opacity-50' : ''}
                                    `}
                                                    key={answer.id_respuesta}
                                                    onClick={() => checkAnswer(answer, index)}
                                                    disabled={answered && selectAnswerIndex !== index}
                                                >
                                                    <p className="font-medium text-center text-sm sm:text-base md:text-lg">
                                                        {answer.respuestaa}
                                                    </p>
                                                </button>
                                            ))
                                        }
                                    </div>


                                    {
                                        indexQuestion + 1 === preguntas.length ? (
                                            <button className="mt-5 w-full h-10 rounded-3xl border border-green-400 bg-green-500 hover:bg-green-700 text-white text-base sm:text-lg md:text-xl md:h-auto md:py-3 md:font-bold md:mt-10"
                                                onClick={() => {
                                                    setAnswered(false);
                                                    setActiveResults(true);
                                                    handleFinalizar();
                                                }}>´Xraqik Li K´anjel</button>
                                        ) : (
                                            <button className="mt-5 w-full h-10 rounded-3xl border border-indigo-400 bg-indigo-500 hover:bg-indigo-700 text-white text-base sm:text-lg md:text-xl md:h-auto md:py-3 md:font-bold md:mt-10" onClick={onNextQuestion}>
                                                Jaln Chik Li Patz´om
                                            </button>

                                        )
                                    }
                                </div>
                            </div>
                        </>
                    )
            }
        </div>


    )
}
