import React, { useEffect, useState } from 'react';
import { QuizAdditional } from '../../../components/Student/Additional/QuizAdditional/QuizAdditional';
import api from '../../../helpers/Api';
import { urlQuizAdicional } from '../../../helpers/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { QuizEvaluation } from '../../../components/Student/Default/Quiz/QuizEvaluation';
import { QuizEvaluationAdditional } from '../../../components/Student/Additional/QuizAdditional/QuizEvaluationAdditional';



const NumeroLeccion = require.context("../../../assets/images/Resultados", true);

const QuizPageAdditional = () => {
    const location = useLocation();

    const Id_Estudiante = location?.state?.PDatos?.IdEstudiante || "";
    const Id_Grado = location?.state?.PDatos?.IdGrado || "";
    const Id_Tema = location?.state?.PDatos?.IdTema || "";
    const Id_Leccion = location?.state?.PDatos?.IdLeccion || "";
    const Id_TipoL = location?.state?.PDatos?.IdTipoL || "";
    const Q_Tiempo = location?.state?.PDatos?.QTiempo || "";
    const Q_Puntaje = location?.state?.PDatos?.QPuntaje || "";
    const Q_Total = location?.state?.PDatos?.QTotal || "";
    const Q_TiempoUso = location?.state?.PDatos?.TiempoUso || "";
    const Q_Imagen = location?.state?.PDatos?.RNumber || "";

    const [preguntas, setPreguntas] = useState([]);
    const [indexQuestion, setIndexQuestion] = useState(0);
    const [activeQuiz, setActiveQuiz] = useState(false);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const getPreguntas = async () => {
            try {
                const response = await api.post(`${urlQuizAdicional}`, { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: Id_Tema, IdLeccion: Id_Leccion });
                setPreguntas(response.data);
                setIsButtonDisabled(false);
            } catch (error) {
                console.log(error);
            }
        }
        getPreguntas();
    }, [Id_Estudiante, Id_Grado, Id_Tema, Id_Leccion]);

    const navigate = useNavigate();

    return (
        <div className='bg-gray-200 h-full'>
            {activeQuiz ? (
                Id_TipoL === 1 ? (
                    <QuizAdditional
                        preguntasFinales={preguntas[indexQuestion]}
                        setIndexQuestion={setIndexQuestion}
                        indexQuestion={indexQuestion}
                        preguntas={preguntas}
                        setActiveQuiz={setActiveQuiz}

                        IdEstudiante={Id_Estudiante}
                        IdGrado={Id_Grado}
                        IdTema={Id_Tema}
                        IdLeccion={Id_Leccion}
                        QTiempo={Q_Tiempo}
                        QPuntaje={Q_Puntaje}
                        QTotal={Q_Total}
                        QTiempoUso={Q_TiempoUso}
                    />
                ) : (
                    <QuizEvaluationAdditional
                        preguntasFinales={preguntas[indexQuestion]}
                        setIndexQuestion={setIndexQuestion}
                        indexQuestion={indexQuestion}
                        preguntas={preguntas}
                        setActiveQuiz={setActiveQuiz}

                        IdEstudiante={Id_Estudiante}
                        IdGrado={Id_Grado}
                        IdTema={Id_Tema}
                        IdLeccion={Id_Leccion}
                        QTiempo={Q_Tiempo}
                        QPuntaje={Q_Puntaje}
                        QTotal={Q_Total}
                        QTiempoUso={Q_TiempoUso}
                    />
                )
            ) : (
                <>
                    <div className="bg-[#EDE7F6] h-full">
                        <div className='h-full mx-auto max-w-2xl px-2 py-2 sm:p-6 lg:max-w-7xl lg:px-8 flex items-center justify-center bg-blue-300'>
                            <div>
                                <div className='my-5 md:mt-5 md:mb-10'>
                                    <h1 className='text-center font-comforter text-2xl sm:text-3xl text-blue-900'>{preguntas[0] && preguntas[0].id_leccion_leccione.nombre_leccion}</h1>
                                </div>

                                <div className="h-64 sm:h-72 p-2 my-5 md:my-10 flex justify-center items-center">
                                    <img
                                        src={NumeroLeccion(`./${Q_Imagen}`)}
                                        alt="#"
                                        className='h-full rounded-xl'
                                    />
                                </div>

                                <div className='p-2 md:p-4'>
                                    <h3 className='text-base text-center'>
                                        Chi sa´ li k´anjel wank ob´ li patz´om, ut aajel ru naq oxib´ taasume chi chaab´il, re naq tatnumeq´ sa´ jalan chik li k´anjel.
                                    </h3>
                                </div>

                                <div className='grid grid-cols-1 gap-4 px-2 py-6 md:py-12 md:grid-cols-2'>
                                    <button
                                        className="text-lg bg-green-200 border border-green-500 py-2 rounded-lg font-bold px-5 transition-all hover:bg-green-500 hover:text-white"
                                        onClick={() => setActiveQuiz(true)} disabled={isButtonDisabled}
                                    >
                                        Hehe´us
                                    </button>
                                    <button
                                        className="text-base bg-red-200 py-2 border border-red-500 rounded-lg font-bold px-5 transition-all hover:bg-red-500 hover:text-white"
                                        onClick={() => navigate("/Estudiantes/InformacionAdicional", { state: { LDatos: { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: Id_Tema, IdLeccion: Id_Leccion, IdTipoL: Id_TipoL, Tiempo: Q_Tiempo, PuntajeL: Q_Puntaje, Total: Q_Total, RandomNumber: Q_Imagen } } })}
                                    >
                                        Ink'a'
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
            }

        </div>
    )
}

export default QuizPageAdditional