import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import Select from 'react-select';
import { urlEliminarTemas, urlGradosDocentes, urlListaTemasEliminadosDocentes, urlTemasDocentesSimples } from '../../../helpers/endpoints';
import api from '../../../helpers/Api';
import { useLocation } from 'react-router-dom';


function DeleteTopics() {
    const location = useLocation();

    const user_D = JSON.parse(localStorage.getItem("user")) || null;

    const Id_Docente = user_D.id_usuario || location?.state?.codigoUsuario;


    const [grados, setGrados] = useState([]);
    const [temasOriginales, setTemasOriginales] = useState([]);

    const [listaTemas, setListaTemas] = useState([])
    const [deleteTema, setDeleteTema] = useState({
        IdTema: null,
        IdGrado: null,
        IdDocente: Id_Docente,
    });

    const [missingFields, setMissingFields] = useState([]);

    //Drop down list para grado
    useEffect(() => {
        const getGrados = async () => {
            try {
                const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
                setGrados(responseGrados.data);
            } catch (error) {
                console.log(error);
            }
        };
        getGrados();
    }, [Id_Docente]);

    const handleChangeDDLGrados = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setDeleteTema({
            ...deleteTema,
            [name]: value,
        })
    };

    //Drop down list para temas
    useEffect(() => {
        const getTemasOriginales = async () => {
            try {
                if (deleteTema.IdGrado !== null) {
                    const responseTemasOriginales = await api.get(`${urlTemasDocentesSimples}?IdDocente=${Id_Docente}&IdGrado=${deleteTema.IdGrado}`);
                    setTemasOriginales(responseTemasOriginales.data);
                }
                else {
                    setTemasOriginales([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getTemasOriginales();
    }, [deleteTema, Id_Docente]);


    const handleChangeDDLTemasOriginales = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;
        //setSelectedTemaId(selectedOption ? selectedOption.value : null);

        setDeleteTema({
            ...deleteTema,
            [name]: value,
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const missingFieldsArray = [];

        if (!deleteTema.IdTema) missingFieldsArray.push('IdTema');
        if (!deleteTema.IdGrado) missingFieldsArray.push('IdGrado');
        if (!deleteTema.IdDocente) missingFieldsArray.push('IdDocente');

        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return; // Sale de la función si la validación falla
        }

        try {
            const response = await api.patch(`${urlEliminarTemas}`, deleteTema);
            const deleteResponse = await api.get(`${urlListaTemasEliminadosDocentes}?IdDocente=${Id_Docente}`);
            setListaTemas(deleteResponse.data);
            Swal.fire({
                icon: 'success',
                title: 'Eliminación Exitosa',
                text: response.data.msg,
            });

            setDeleteTema({
                IdTema: null,
                NombreTema: "",
                DescripcionGeneral: "",
                IdGrado: null,
                IdDocente: Id_Docente,
            });

        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al eliminar el tema. Por favor, inténtelo de nuevo.',
            });
        }
    };


    useEffect(() => {
        const getTemas = async () => {
            try {
                const response = await api.get(`${urlListaTemasEliminadosDocentes}?IdDocente=${Id_Docente}`);
                setListaTemas(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getTemas();
    }, [Id_Docente]);

    return (
        <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-[#EDE7F6]'>
            <div className='flex justify-center py-5'>
                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Eliminar Temas</h1>
            </div>
            <div className="px-4 md:px-10 lg:px-20 my-5">
                <form onSubmit={handleSubmit}>
                    <div className=''>
                        <label className="text-base font-medium">Grado: </label>
                        <Select
                            options={grados}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                            ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false} // Para deshabilitar la búsqueda
                            name='IdGrado'
                            placeholder="Seleccione un Grado"
                            onChange={handleChangeDDLGrados}
                            value={grados.find(grado => grado.value === deleteTema.IdGrado) || null}
                        />
                    </div>

                    <div className=''>
                        <label className="text-base font-medium">Tema: </label>
                        <Select
                            options={temasOriginales}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                            ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false} // Para deshabilitar la búsqueda
                            name='IdTema'
                            placeholder="Seleccione un Tema"
                            onChange={handleChangeDDLTemasOriginales}
                            value={temasOriginales.find(tema => tema.value === deleteTema.IdTema) || null}
                        />
                    </div>

                    <div className="mt-4 w-full">
                        <button
                            className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500"
                            type="submit"
                        >
                            Eliminar Tema
                        </button>
                    </div>
                </form>

            </div>

            <div className='px-4 md:px-10 lg:px-20 mt-10'>
                <table className="bg-gray-100 min-w-full">
                    <thead className='bg-gray-200'>
                        <tr className='border-2 border-indigo-500'>
                            <th className="py-2 px-4 border-b">
                                <h2>
                                    Grado
                                </h2>
                            </th>
                            <th className="py-2 px-4 border-b">
                                <h2>
                                    Tema
                                </h2>
                            </th>
                            <th className="py-2 px-4 border-b">
                                Estado
                            </th>
                        </tr>
                    </thead>
                    <tbody className='border-2 border-indigo-500'>

                        {

                            Array.isArray(listaTemas) && listaTemas.map((listaTema) => (
                                <tr key={listaTema.id_tema}>
                                    <td className='className="py-2 px-4 border-b"'>
                                        <h3 className='w-full flex justify-center'>
                                            {listaTema.temas_x_grados[0].id_grado_grado.nombre_grado}
                                        </h3>
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <h3 className='w-full flex justify-center'>
                                            {listaTema.nombre_tema}
                                        </h3>
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <h3 className='flex justify-center bg-red-100 rounded-xl text-rose-700'>
                                            Eliminado
                                        </h3>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>

        </div>

    )
}

export default DeleteTopics