import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import foto from '../../assets/images/kid-821920111.png'
import utilesEscolares from "../../assets/images/UtilesEscolares3.png";
import prueba from "../../assets/images/UtilesEscolares3.png";
import { motion } from "framer-motion";
import { urlLogin } from "../../helpers/endpoints";
import Swal from "sweetalert2";
import { ParticlesT } from "../ParticlesT";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import { AuthContext } from "../../helpers/authContext";
import api from "../../helpers/Api";


export const Login = () => {

  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [missingFields, setMissingFields] = useState([]);

  const [credentials, setCredentials] = useState({
    CodigoUsuario: "",
    Contraseña: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingFieldsArray = [];
    if (!credentials.CodigoUsuario) missingFieldsArray.push('CodigoUsuario');
    if (!credentials.Contraseña) missingFieldsArray.push('Contraseña');

    setMissingFields(missingFieldsArray);

    if (missingFieldsArray.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
      });
      return; // Sale de la función si la validación falla
    }
    setIsLoading(true);

    try {
      const response = await api.post(
        `${urlLogin}`,
        credentials
      );
      setUser(response.data);
      // const { id_usuario, id_rol, p_nombre, p_apellido } = response.data;
      localStorage.setItem('user', JSON.stringify(response.data));

      // Redirigir según el rol
      if (response.data.id_rol === 1) {
        navigate('/Docentes')
      }
      else if (response.data.id_rol === 2) {
        navigate('/Estudiantes')
      } else {
        navigate('/')
      }



    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Credenciales Incorrectas",
      });

    } finally {
      setIsLoading(false);
    }
  };

  return (

    <main className="flex w-full h-screen bg-indigo-300">
      <ParticlesT />

      <div className="relative h-full w-full flex items-center justify-center xl:w-1/2">
        <div className="flex rounded-3xl md:p-10 lg:rounded-none lg:w-full lg:h-full lg:justify-center xl:justify-normal xl:pl-10 xl:pr-10 xl:py-14">

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 1 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            className="xl:w-9/12 xl:p-0 flex flex-col">
            <div className="w-full py-3 bg-blue-800 rounded-t-3xl">
              <h1 className="text-center text-lg sm:text-xl md:text-3xl text-white">
                Escuela Oficial Rural Mixta
              </h1>
              <h1 className="text-center text-lg sm:text-xl md:text-3xl text-white">
                Sacsi Chiyo
              </h1>
            </div>

            <div className="relative h-full bg-white px-10 pt-10 pb-10 md:px-20 rounded-b-3xl border-4 border-blue-800">

              <h1 className="text-xl sm:text-2xl md:text-4xl text-center font-semibold">
                Sahil Ch´olejil Leek´ulunik
              </h1>
              <form className="mt-10" onSubmit={handleSubmit}>
                <div>
                  <label className="text-sm sm:text-base font-medium">Xchaq´rab´il Aj Tzolonel</label>
                  <input
                    className={`text-sm sm:text-base w-full border-4 border-indigo-400 focus:outline-none focus:border-blue-800 focus:ring-blue-800 rounded-xl p-2 mt-1
                      ${missingFields.includes('CodigoUsuario') ? 'border-red-500' : ''}`
                    }
                    type="number"
                    name="CodigoUsuario"
                    placeholder="Ingrese su Código de Estudiante"
                    onChange={handleChange}
                  />
                </div>

                <div className="mt-4">
                  <label className="text-sm sm:text-base font-medium">Eetalil</label>

                  <div className="relative">
                    <input
                      className={`text-sm sm:text-base w-full border-4 border-indigo-400 focus:outline-none focus:border-blue-800 focus:ring-blue-800 rounded-xl p-2 mt-1
                          ${missingFields.includes('Contraseña') ? 'border-red-500' : ''}`
                      }
                      type={showPassword ? "text" : "password"}
                      name="Contraseña"
                      autoComplete="off"
                      placeholder="Ingrese su Contraseña"
                      onChange={handleChange}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center p-2 mt-1 mr-2 cursor-pointer" onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <ImEye className="text-blue-800 hover:text-indigo-500" />
                      ) : (
                        <ImEyeBlocked className="text-blue-800 hover:text-indigo-500" />
                      )}
                    </div>


                  </div>

                </div>

                <div className="mt-8 flex flex-col">
                  <button
                    className="text-base sm:text-lg active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-r from-blue-800 to-indigo-400 text-white font-bold px-4 hover:bg-gradient-to-r hover:from-blue-800 hover:to-blue-800"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "..." : "IXb´ee Yalb´a´ix"}
                  </button>
                </div>
              </form>

            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 4 }}
            className="hidden lg:flex relative w-20">
            <div className="absolute w-80 h-full -left-full -top-5 flex flex-col">
              <img src={foto} className="" alt="" />
            </div>
          </motion.div>


        </div>
      </div>


      <div className="hidden xl:flex h-full w-1/2">
        <div className="h-full w-full bg-gradient-to-r from-indigo-300 to-indigo-50">
          <div className="h-full w-full flex flex-col">
            <motion.div
              animate={{ x: [700, 0, 0] }}
              transition={{ duration: 4 }}
              className="h-auto  w-full pt-5 flex flex-col justify-center items-center">
              <h1 className="text-blue-800 text-xl sm:text-4xl md:text-6xl font-comforter">
                Comunicación
              </h1>
              <h1 className="text-blue-800 text-6xl font-comforter">
                y
              </h1>
              <h1 className="text-blue-800 text-6xl font-comforter">
                Lenguaje L2
              </h1>
            </motion.div>


            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 4 }}
              className="w-full h-full flex items-center">
              <img
                className="" src={utilesEscolares} alt="#" />
            </motion.div>



          </div>
        </div>
      </div>
    </main>
  );
};
