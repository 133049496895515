import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { ResultsAdditional } from '../ResultsAdditional';

export const QuizEvaluationAdditional = ({
  preguntasFinales,
  setIndexQuestion,
  indexQuestion,
  preguntas,
  setActiveQuiz,

  IdEstudiante,
  IdGrado,
  IdTema,
  IdLeccion,
  QTiempo,
  QPuntaje,
  QTotal,
  QTiempoUso
}) => {
  const [activeResults, setActiveResults] = useState(false);
  const [score, setScore] = useState(0);
  const [indice, setIndice] = useState(0);
  const [input, setInput] = useState("");
  const [questionStatus, setQuestionStatus] = useState(Array(preguntas.length).fill(null));

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [hoverOut, setHoverOut] = useState(false);

  useEffect(() => {
    setStartTime(new Date().toISOString());
  }, []);

  const handleFinalizar = () => {
    setEndTime(new Date().toISOString());
  };

  const onReset = () => {
    setScore(0);
    setActiveQuiz(false);
    setIndexQuestion(0);
    setStartTime(null);
    setEndTime(null);
  };

  const preguntaActual = preguntas[indice];
  const palabraCorrecta = preguntaActual.respuesta.find(r => r.correcta === "1").respuestaa;

  const ocultarLetras = (palabra) => {
    let caracteres = palabra.split("");
    let totalLetras = caracteres.filter(c => c !== " ").length;
    let letrasVisibles = 2; // Mínimo de 2 letras visibles

    if (totalLetras === 1) {
      letrasVisibles = 1;
    } else if (totalLetras === 2) {
      letrasVisibles = 1;
    } else if (totalLetras === 3) {
      letrasVisibles = 1;
    } else {
      letrasVisibles = Math.max(2, Math.ceil(totalLetras * 0.3));
    }

    let indicesVisibles = new Set();
    while (indicesVisibles.size < letrasVisibles) {
      let indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      if (caracteres[indiceAleatorio] !== " ") {
        indicesVisibles.add(indiceAleatorio);
      }
    }

    return caracteres.map((c, i) => indicesVisibles.has(i) ? c : (c === " " ? " " : "_")).join("");
  };

  const [palabraOculta, setPalabraOculta] = useState(ocultarLetras(palabraCorrecta));

  useEffect(() => {
    setPalabraOculta(ocultarLetras(palabraCorrecta));
  }, [indice]);

  const manejarRespuesta = () => {
    const respuestaNormalizada = input.trim().replace(/\s+/g, " ");
    const esCorrecta = respuestaNormalizada.toLowerCase() === palabraCorrecta.toLowerCase();

    if (esCorrecta) {
      setScore(score + 1);
      questionStatus[indexQuestion] = 'correct';
    } else {
      questionStatus[indexQuestion] = 'incorrect';
    }

    setQuestionStatus([...questionStatus]);
    setInput("");

    if (indice < preguntas.length - 1) {
      setIndice(indice + 1);
      setIndexQuestion(indexQuestion + 1);
    } else {
      setActiveResults(true);
      handleFinalizar();
    }
  };

  return (
    <div className='bg-gray-200 h-full'>
      {activeResults ? (
        <ResultsAdditional
          preguntas={preguntas}
          score={score}
          onReset={onReset}
          Inicio={startTime}
          Final={endTime}
          Id_Estudiante={IdEstudiante}
          Id_Grado={IdGrado}
          Id_Tema={IdTema}
          Id_Leccion={IdLeccion}
          Q_Tiempo={QTiempo}
          Q_Puntaje={QPuntaje}
          Q_Total={QTotal}
          Q_TiempoUso={QTiempoUso}
        />
      ) : (
        <div className="h-full mx-auto max-w-2xl px-2 py-2 sm:p-6 lg:max-w-7xl lg:px-8 bg-[#EDE7F6]">
          <div className="w-10 mb-5" onMouseEnter={() => setHoverOut(true)} onMouseLeave={() => setHoverOut(false)}>
            <button className="flex justify-center items-center font-bold border border-gray-500 p-1 rounded-lg transition-all text-gray-500 hover:p-2 hover:bg-red-400 hover:border-white hover:text-white" onClick={onReset}>
              <IoIosArrowBack className='font-bold text-lg' />
              <h3 className={`text-sm ${hoverOut ? 'block' : 'hidden'}`}>Abandonar</h3>
            </button>
          </div>

          <h1 className='text-center font-comforter text-lg sm:text-2xl md:text-3xl text-blue-900 my-5'>
            {preguntasFinales.id_leccion_leccione.nombre_leccion}
          </h1>

          {/* contador */}
          <div className='flex flex-wrap justify-center items-start gap-2 sm:gap-5 my-5'>
            {preguntas.map((_, index) => (
              <div key={index} className="w-10 aspect-[4/4] rounded-full flex flex-col justify-center items-center">
                {questionStatus[index] === 'correct' ? (
                  <div className='w-full h-full rounded-full border-2 border-green-500 flex flex-col justify-center items-center bg-indigo-200'>
                    <RiCheckLine className='text-2xl text-green-500' />
                  </div>
                ) : questionStatus[index] === 'incorrect' ? (
                  <div className='w-full h-full rounded-full border-2 border-red-500 flex flex-col justify-center items-center bg-indigo-200'>
                    <RiCloseLine className='text-2xl text-red-500' />
                  </div>
                ) : (
                  <div className='w-full h-full rounded-full border-2 border-indigo-500 flex flex-col justify-center items-center bg-indigo-200'>
                    <div className='w-full h-full flex items-center justify-center text-2xl font-semibold text-indigo-500'>
                      {index + 1}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className='flex justify-center py-2 mb-2 md:my-5'>
            <h3 className='font-semibold text-sm sm:text-lg md:text-xl'>{preguntaActual.pregunta}</h3>
          </div>

          <div className="flex flex-col w-full text-center items-center">
            <p className="text-2xl font-semibold mt-4 tracking-widest mb-5">{palabraOculta}</p>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="mt-4 p-2 border-2 border-gray-400 rounded-md text-center text-xl w-full md:w-3/4 lg:1/2"
              placeholder="Tz'iib'a li aatin tzaqal re ru"
            />

          </div>


          <button
            className={`mt-5 w-full h-10 rounded-3xl text-white text-base sm:text-lg md:text-xl md:h-auto md:py-3 md:font-bold md:mt-10
              ${indexQuestion + 1 === preguntas.length ? 'bg-green-500 hover:bg-green-700' : 'bg-indigo-500 hover:bg-indigo-700'}
              ${!input.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={manejarRespuesta}
            disabled={!input.trim()}
          >
            {indexQuestion + 1 === preguntas.length ? "'Xraqik Li K'anjel" : "Jaln Chik Li Patz'om"}
          </button>
        </div>
      )}
    </div>
  );
}
