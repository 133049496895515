import React, { useEffect, useState } from 'react'
import DeleteQuestions from '../../../components/Teacher/Delete/DeleteQuestions'
import { urlDDLLecciones, urlGradosDocentes, urlTemasDocentesSimples } from '../../../helpers/endpoints';
import api from '../../../helpers/Api';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Select from 'react-select';

const DeleteQuestionsPage = () => {
    const location = useLocation();

    const user_D = JSON.parse(localStorage.getItem("user")) || null;

    const Id_Docente = user_D.id_usuario || location?.state?.codigoUsuario;


    const [grados, setGrados] = useState([]);
    const [temas, setTemas] = useState([]);
    const [lecciones, setLecciones] = useState([]);
    const [activo, setActivo] = useState(false);

    const [deletePreguntas, setDeletePreguntas] = useState({
        IdDocente: Id_Docente,
        IdGrado: null,
        IdTema: null,
        IdLeccion: null,
    });

    const [missingFields, setMissingFields] = useState([]);


    //Drop down list para grado
    useEffect(() => {
        const getGrados = async () => {
            try {
                const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
                setGrados(responseGrados.data);
            } catch (error) {
                console.log(error);
            }
        };
        getGrados();
    }, [Id_Docente]);
    const handleChangeDDLGrados = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setDeletePreguntas({
            ...deletePreguntas,
            [name]: value,
        })
    };

    //Drop down list para temas
    useEffect(() => {
        const getTemas = async () => {
            try {
                if (deletePreguntas.IdGrado !== null) {
                    const responseTemas = await api.get(`${urlTemasDocentesSimples}?IdDocente=${Id_Docente}&IdGrado=${deletePreguntas.IdGrado}`);
                    setTemas(responseTemas.data);
                }
                else {
                    setTemas([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getTemas();
    }, [deletePreguntas, Id_Docente]);
    const handleChangeDDLTemas = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setDeletePreguntas({
            ...deletePreguntas,
            [name]: value,
        })
    };

    //Drop down list de lecciones
    useEffect(() => {
        const getLecciones = async () => {
            try {
                if (deletePreguntas.IdTema !== null) {
                    const responseLecciones = await api.get(`${urlDDLLecciones}?IdDocente=${Id_Docente}&IdGrado=${deletePreguntas.IdGrado}&IdTema=${deletePreguntas.IdTema}`);
                    setLecciones(responseLecciones.data);
                }
                else {
                    setLecciones([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLecciones();
    }, [deletePreguntas, Id_Docente]);
    const handleChangeDDLLecciones = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setDeletePreguntas({
            ...deletePreguntas,
            [name]: value,
        });
    };













    const confirmData = () => {

        const missingFieldsArray = [];

        if (!deletePreguntas.IdDocente) missingFieldsArray.push('IdDocente');
        if (!deletePreguntas.IdGrado) missingFieldsArray.push('IdGrado');
        if (!deletePreguntas.IdTema) missingFieldsArray.push('IdTema');
        if (!deletePreguntas.IdLeccion) missingFieldsArray.push('IdLeccion');

        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return; // Sale de la función si la validación falla
        }

        try {

            setActivo(true);
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al eliminar el tema. Por favor, inténtelo de nuevo.',
            });
        }
    };






    return (
        <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-[#EDE7F6]'>
            {
                activo ?
                    (
                        <DeleteQuestions
                            setActivo={setActivo}
                            deletePreguntas={deletePreguntas}
                        />
                    ) :
                    (
                        <>
                            <div className='flex justify-center py-5'>
                                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Eliminar Preguntas</h1>
                            </div>

                            <div className="px-4 md:px-10 lg:px-20 my-5">
                                <div className=''>
                                    <label className="text-base font-medium">Grado: </label>
                                    <Select
                                        options={grados}
                                        className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                                ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                                        classNamePrefix="select"
                                        isSearchable={false} // Para deshabilitar la búsqueda
                                        name='IdGrado'
                                        placeholder="Seleccione un Grado"
                                        onChange={handleChangeDDLGrados}
                                        value={grados.find(grado => grado.value === deletePreguntas.IdGrado) || null}
                                    />
                                </div>

                                <div className=''>
                                    <label className="text-base font-medium">Tema: </label>
                                    <Select
                                        options={temas}
                                        className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                                    ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                                        classNamePrefix="select"
                                        isSearchable={false} // Para deshabilitar la búsqueda
                                        name='IdTema'
                                        placeholder="Seleccione un Tema"
                                        onChange={handleChangeDDLTemas}
                                        value={temas.find(tema => tema.value === deletePreguntas.IdTema) || null}
                                    />
                                </div>

                                <div className=''>
                                    <label className="text-base font-medium">Lección: </label>
                                    <Select
                                        options={lecciones}
                                        className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                                ${missingFields.includes('IdLeccion') ? 'border-red-500' : ''}`}
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        name='IdLeccion'
                                        placeholder="Seleccione la Lección"
                                        onChange={handleChangeDDLLecciones}
                                        value={lecciones.find(leccionU => leccionU.value === deletePreguntas.IdLeccion) || null}
                                    />
                                </div>


                                <div className="mt-4 w-full">
                                    <button
                                        className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500"
                                        onClick={() => {
                                            confirmData();
                                        }}
                                    >
                                        Eliminar Preguntas
                                    </button>
                                </div>


                            </div>


                        </>
                    )
            }



        </div>
    )
}

export default DeleteQuestionsPage