import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../helpers/authContext';

const ProtectedRoute = ({ children, role }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) return <div className="spinner">Cargando...</div>;

  // console.log(user);
  // if (!user || (role && String(user.id_rol) !== String(role))) {
  //   return <Navigate to="/" />; // Redirige al login si no está autenticado o el rol no coincide
  // }
  // else {
  //   return children;

  // }

  if (!user) {
    return <Navigate to="/" />;
  }

  if (role && String(user.id_rol) !== String(role)) {
    return <Navigate to="/" />;
  }
  return children;

};

export default ProtectedRoute;
