import React, { useEffect, useState } from 'react'
import { urlCreatePreguntas, urlDDLLecciones, urlGradosDocentes, urlTemasDocentesSimples } from '../../../helpers/endpoints';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import api from '../../../helpers/Api';
import Swal from 'sweetalert2';



function CreateQuestions() {
    const location = useLocation();

    const Id_Docente = location?.state?.codigoUsuario || "Usuario";

    const [grados, setGrados] = useState([]);
    const [temas, setTemas] = useState([]);
    const [leccion, setLeccion] = useState([]);

    const [preguntasData, setPreguntasData] = useState({
        IdDocente: Id_Docente,
        IdGrado: null,
        IdTema: null,
        IdLeccion: null,
        PreguntasU: [
            {
                PreguntaU: '',
                RespuestasU: [
                    {
                        RespuestaU: '',
                        CorrectaU: true,
                    },
                    {
                        RespuestaU: '',
                        CorrectaU: false,
                    },
                    {
                        RespuestaU: '',
                        CorrectaU: false,
                    },
                    {
                        RespuestaU: '',
                        CorrectaU: false,
                    },
                ],
            },
        ],
    });

    const [missingFields, setMissingFields] = useState([]);


    //Drop down list para grados
    useEffect(() => {
        const getGrados = async () => {
            try {
                const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
                setGrados(responseGrados.data);
            } catch (error) {
                console.log(error);
            }
        };
        getGrados();
    }, [Id_Docente]);

    const handleChangeDDLGrados = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setPreguntasData({
            ...preguntasData,
            [name]: value,
        });
    };


    //Drop down list para temas
    useEffect(() => {
        const getTemas = async () => {
            try {
                if (preguntasData.IdGrado !== null) {
                    const responseTemas = await api.get(`${urlTemasDocentesSimples}?IdDocente=${Id_Docente}&IdGrado=${preguntasData.IdGrado}`);
                    setTemas(responseTemas.data);
                }
                else {
                    setTemas([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getTemas();
    }, [preguntasData, Id_Docente]);

    const handleChangeDDLTemas = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;
        //setSelectedTemaId(selectedOption ? selectedOption.value : null);

        setPreguntasData({
            ...preguntasData,
            [name]: value,
        });
    };


    //Drop down list de lecciones
    useEffect(() => {
        const getLecciones = async () => {
            try {
                if (preguntasData.IdTema !== null) {
                    const responseLecciones = await api.get(`${urlDDLLecciones}?IdDocente=${Id_Docente}&IdGrado=${preguntasData.IdGrado}&IdTema=${preguntasData.IdTema}`);
                    setLeccion(responseLecciones.data);
                }
                else {
                    setLeccion([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLecciones();
    }, [preguntasData, Id_Docente]);

    const handleChangeDDLLecciones = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setPreguntasData({
            ...preguntasData,
            [name]: value,
        });
    };


    const handleChange = (e, questionIndex, answerIndex) => {
        const { name, value } = e.target;
        const updatedPreguntas = [...preguntasData.PreguntasU];

        if (name === 'PreguntaU') {
            updatedPreguntas[questionIndex][name] = value;
        } else if (name === 'RespuestaU') {
            updatedPreguntas[questionIndex].RespuestasU[answerIndex][name] = value;
        }


        setPreguntasData({
            ...preguntasData,
            PreguntasU: updatedPreguntas
        });
    };

    const handleAddExample = () => {
        setPreguntasData({
            ...preguntasData,
            PreguntasU: [...preguntasData.PreguntasU,
            {
                PreguntaU: '',
                RespuestasU: [
                    { RespuestaU: '', CorrectaU: true },
                    { RespuestaU: '', CorrectaU: false },
                    { RespuestaU: '', CorrectaU: false },
                    { RespuestaU: '', CorrectaU: false },
                ],
            },
            ],
        });
    };

    const handleRemoveExample = (index) => {
        const updatedPreguntas = [...preguntasData.PreguntasU];
        if (updatedPreguntas.length > 1) {
            updatedPreguntas.splice(index, 1);
            setPreguntasData({
                ...preguntasData,
                PreguntasU: updatedPreguntas
            });
        }
        else {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                icon: 'warning',
                title: 'Debe haber al menos un ejemplo con su traducción.',
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
        }
    };


    // const renderIcon = (isChecked) => {
    //     if (isChecked) {
    //         return <div className='rounded-full border border-green-500 p-1'>
    //             <FaCheck className="text-green-500" />
    //              <label className='ml-2'>Correcta</label>
    //         </div>;
    //     } else {
    //         return <div className='rounded-full border-2 border-red-500 p-1'>
    //             <FaTimes className="text-red-500" />
    // <label className='ml-2'>Incorrecta</label>
    //         </div>;
    //     }
    // };


    const handleSelectCorrectAnswer = (questionIndex, correctAnswerIndex) => {
        const updatedPreguntas = [...preguntasData.PreguntasU];
        updatedPreguntas[questionIndex].RespuestasU.forEach((answer, index) => {
            answer.CorrectaU = index === correctAnswerIndex;
        });
        setPreguntasData({
            ...preguntasData,
            PreguntasU: updatedPreguntas,
        })
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const missingFieldsArray = [];
        if (!preguntasData.IdDocente) missingFieldsArray.push('IdDocente');
        if (!preguntasData.IdGrado) missingFieldsArray.push('IdGrado');
        if (!preguntasData.IdTema) missingFieldsArray.push('IdTema');
        if (!preguntasData.IdLeccion) missingFieldsArray.push('IdLeccion');


        if (!preguntasData.PreguntasU.every((example) => example.PreguntaU)) missingFieldsArray.push('PreguntaU');
        if (!preguntasData.PreguntasU.every((example) => example.RespuestasU.every((expa) => expa.RespuestaU))) missingFieldsArray.push('RespuestaU');

        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return; // Sale de la función si la validación falla
        }

        try {
            const responsePreguntas = await api.post(`${urlCreatePreguntas}`, preguntasData);
            console.log("Esto regresa en preguntas data: ", responsePreguntas);

            Swal.fire({
                icon: 'success',
                title: 'Agregado',
                // text: responsePreguntas.data.msg,
                text: "Preguntas Agregadas"
            });

            setPreguntasData({
                IdDocente: Id_Docente,
                IdGrado: null,
                IdTema: null,
                IdLeccion: null,
                PreguntasU: [
                    {
                        PreguntaU: '',
                        RespuestasU: [
                            {
                                RespuestaU: '',
                                CorrectaU: true,
                            },
                            {
                                RespuestaU: '',
                                CorrectaU: false,
                            },
                            {
                                RespuestaU: '',
                                CorrectaU: false,
                            },
                            {
                                RespuestaU: '',
                                CorrectaU: false,
                            },
                        ],
                    },
                ],
            });
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.error,
            });
        }


    };



    return (
        <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-[#EDE7F6]'>
            <div className='flex justify-center py-5'>
                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Crear Preguntas</h1>
            </div>


            <div className='px-4 md:px-10 lg:px-10 my-5'>
                <form className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6' onSubmit={handleSubmit}>
                    <div className=''>
                        <label className="text-base font-medium">Grado: </label>
                        <Select
                            options={grados}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                    ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false}
                            name='IdGrado'
                            placeholder="Seleccione un Grado"
                            onChange={handleChangeDDLGrados}
                            value={grados.find(grado => grado.value === preguntasData.IdGrado) || null}
                        />
                    </div>

                    <div className=''>
                        <label className="text-base font-medium">Tema: </label>
                        <Select
                            options={temas}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                    ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false}
                            name='IdTema'
                            placeholder="Seleccione un Tema"
                            onChange={handleChangeDDLTemas}
                            value={temas.find(tema => tema.value === preguntasData.IdTema) || null}
                        />
                    </div>

                    <div className=''>
                        <label className="text-base font-medium">Lección: </label>
                        <Select
                            options={leccion}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                    ${missingFields.includes('IdLeccion') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false}
                            name='IdLeccion'
                            placeholder="Seleccione la Lección"
                            onChange={handleChangeDDLLecciones}
                            value={leccion.find(leccionU => leccionU.value === preguntasData.IdLeccion) || null}
                        />
                    </div>


                    <div className='mb-10 lg:mb-0 px-2 pt-2 pb-4 lg:px-4 lg:pt-4 lg:pb-6 lg:col-span-2 rounded-xl bg-sky-300'>
                        <div className="mb-2">
                            <label className='text-base font-medium'>Ingrese las preguntas y respuestas de la evaluación.: </label>
                        </div>
                        {preguntasData.PreguntasU.map((question, questionIndex) => (
                            <div key={questionIndex} className='mb-4 px-1 py-4 lg:p-4 grid grid-cols-1 gap-4 lg:grid-cols-10 lg:gap-6 rounded-xl bg-orange-200'>
                                <input
                                    className={`w-full p-2 mb-2 lg:mt-0 border-2 lg:col-span-10 border-indigo-500 rounded
                                                ${missingFields.includes('PreguntaU') ? 'border-red-500' : ''}`}
                                    type="text"
                                    name="PreguntaU"
                                    placeholder="Ingrese su pregunta."
                                    value={question.PreguntaU}
                                    onChange={(e) => handleChange(e, questionIndex)}
                                />

                                <label className='text-base font-medium lg:col-span-10'>Respuestas:</label>
                                {question.RespuestasU.map((answer, answerIndex) => (
                                    <div key={answerIndex} className='lg:col-span-5'>
                                        <input
                                            className={`w-full px-2 py-1 border-2 border-indigo-500 rounded
                                            ${missingFields.includes('RespuestaU') ? 'border-red-500' : ''}`}
                                            type="text"
                                            name='RespuestaU'
                                            placeholder={`RespuestaU ${answerIndex + 1}`}
                                            value={answer.RespuestaU}
                                            onChange={(e) => handleChange(e, questionIndex, answerIndex)}
                                        />

                                        <label className="inline-flex items-center py-2">
                                            <input
                                                type="radio"
                                                name={`correctAnswer-${questionIndex}`}
                                                checked={answer.CorrectaU}
                                                onChange={() => handleSelectCorrectAnswer(questionIndex, answerIndex)}
                                                // className="hidden"
                                                className='w-5 h-5'
                                            />
                                            {/* {renderIcon(answer.Correcta)} */}
                                            <label className='ml-2'>Correcta</label>
                                        </label>
                                    </div>
                                ))}



                                <button className='w-full lg:col-start-4 lg:col-span-4 active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-1 rounded-xl bg-red-500 text-white text-lg font-bold px-4 hover:bg-red-600' type="button" onClick={() => handleRemoveExample(questionIndex)}>
                                    Eliminar Pregunta
                                </button>
                            </div>
                        ))}
                        <button className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-1 rounded-xl bg-green-500 text-white text-lg font-bold px-4 hover:bg-green-600' type="button" onClick={handleAddExample}>
                            Agregar Pregunta
                        </button>
                    </div>



                    <button type="submit" className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl lg:col-span-2 bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500'>Guardar Preguntas</button>

                </form>


            </div>


        </div>
    )
}

export default CreateQuestions