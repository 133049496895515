import React, { useEffect, useState } from 'react'
import UpdateQuestions from '../../../components/Teacher/Update/UpdateQuestions'
import api from '../../../helpers/Api';
import { urlDDLLecciones, urlGradosDocentes, urlTemasDocentesSimples } from '../../../helpers/endpoints';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';

const UpdateQuestionsPage = () => {

    const location = useLocation();
    const Id_Docente = location?.state?.codigoUsuario || "Usuario";

    const [grados, setGrados] = useState([]);
    const [temas, setTemas] = useState([]);
    const [leccion, setLeccion] = useState([]);

    const [activo, setActivo] = useState(false);

    const [updatePreguntas, setUpdatePreguntas] = useState({
        IdGrado: null,
        IdTema: null,
        IdLeccion: null,
        IdDocente: Id_Docente,
    });

    const [missingFields, setMissingFields] = useState([]);


    //Drop down list para grados
    useEffect(() => {
        const getGrados = async () => {
            try {
                const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
                setGrados(responseGrados.data);
            } catch (error) {
                console.log(error);
            }
        };
        getGrados();
    }, [Id_Docente]);

    const handleChangeDDLGrados = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setUpdatePreguntas({
            ...updatePreguntas,
            [name]: value,
        });
    };


    //Drop down list para temas
    useEffect(() => {
        const getTemas = async () => {
            try {
                if (updatePreguntas.IdGrado !== null) {
                    const responseTemas = await api.get(`${urlTemasDocentesSimples}?IdDocente=${Id_Docente}&IdGrado=${updatePreguntas.IdGrado}`);
                    setTemas(responseTemas.data);
                }
                else {
                    setTemas([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getTemas();
    }, [updatePreguntas, Id_Docente]);

    const handleChangeDDLTemas = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setUpdatePreguntas({
            ...updatePreguntas,
            [name]: value,
        });
    };


    //Drop down list de lecciones
    useEffect(() => {
        const getLecciones = async () => {
            try {
                if (updatePreguntas.IdTema !== null) {
                    const responseLecciones = await api.get(`${urlDDLLecciones}?IdDocente=${Id_Docente}&IdGrado=${updatePreguntas.IdGrado}&IdTema=${updatePreguntas.IdTema}`);
                    setLeccion(responseLecciones.data);
                }
                else {
                    setLeccion([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLecciones();
    }, [updatePreguntas, Id_Docente]);

    const handleChangeDDLLecciones = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setUpdatePreguntas({
            ...updatePreguntas,
            [name]: value,
        });
    };



    const confirmData = () => {

        const missingFieldsArray = [];
        if (!updatePreguntas.IdDocente) missingFieldsArray.push('IdDocente');
        if (!updatePreguntas.IdGrado) missingFieldsArray.push('IdGrado');
        if (!updatePreguntas.IdTema) missingFieldsArray.push('IdTema');
        if (!updatePreguntas.IdLeccion) missingFieldsArray.push('IdLeccion');


        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return;
        }

        try {

            setActivo(true);

        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.error,
            });
        }

    };



    return (
        <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-[#EDE7F6]'>
            {
                activo ?
                    (
                        <UpdateQuestions
                            setActivo={setActivo}
                            updatePreguntas={updatePreguntas}
                        />
                    ) :
                    (
                        <>
                            <div className='flex justify-center py-5'>
                                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Modificar Preguntas y Respuestas</h1>
                            </div>

                            <div className='px-4 md:px-10 lg:px-10 my-5'>
                                <div className='grid grid-cols-1 lg:gap-6'>
                                    <div className='mb-2 lg:mx-10'>
                                        <label className="text-sm sm:text-base font-medium">Grado: </label>
                                        <Select
                                            options={grados}
                                            className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                                ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            name='IdGrado'
                                            placeholder="Seleccione un Grado"
                                            onChange={handleChangeDDLGrados}
                                            value={grados.find(grado => grado.value === updatePreguntas.IdGrado) || null}
                                        />
                                    </div>

                                    <div className='mb-2 lg:mx-10'>
                                        <label className="text-sm sm:text-base font-medium">Tema: </label>
                                        <Select
                                            options={temas}
                                            className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                                ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            name='IdTema'
                                            placeholder="Seleccione un Tema"
                                            onChange={handleChangeDDLTemas}
                                            value={temas.find(tema => tema.value === updatePreguntas.IdTema) || null}
                                        />
                                    </div>

                                    <div className='mb-2 lg:mx-10'>
                                        <label className="text-sm sm:text-base font-medium">Lección: </label>
                                        <Select
                                            options={leccion}
                                            className={`w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded
                                                ${missingFields.includes('IdLeccion') ? 'border-red-500' : ''}`}
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            name='IdLeccion'
                                            placeholder="Seleccione la Lección"
                                            onChange={handleChangeDDLLecciones}
                                            value={leccion.find(leccionU => leccionU.value === updatePreguntas.IdLeccion) || null}
                                        />
                                    </div>


                                    <div className='mt-5 lg:mx-10'>
                                        <button className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl lg:col-span-2 bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500'
                                            onClick={() => {
                                                confirmData();
                                            }}
                                        >
                                            Modificar
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </>

                    )
            }



        </div>


    )
}

export default UpdateQuestionsPage