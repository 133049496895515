import React, { useEffect, useState } from 'react'
// import { Navbar } from './NavbarTeacher'
import { jwtDecode } from "jwt-decode";
// import { FaRegUserCircle, FaTools } from 'react-icons/fa';
import { PiStudent } from "react-icons/pi";
import { BsChevronDown, BsClipboardCheckFill } from 'react-icons/bs';
// import { CiTrophy } from 'react-icons/ci';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import api from '../../helpers/Api';
import { urlGradosDocentes, urlListaResultados, urlTemasDocentes } from '../../helpers/endpoints';

function HomeTeacher() {
  const location = useLocation();

  const user_D = JSON.parse(localStorage.getItem("user")) || null;


  const Id_Docente = user_D.id_usuario || location?.state?.codigoUsuario || "";

  const [openStudent, setOpenStudent] = useState(null);

  const [grados, setGrados] = useState([]);
  const [temas, setTemas] = useState([]);
  const [restoreTema, setRestoreTema] = useState({
    IdTema: null,
    IdGrado: null,
    IdDocente: Id_Docente,
  });

  const [resultadosEstudiantes, setResultadosEstudiantes] = useState([]);

  useEffect(() => {
    const getGrados = async () => {
      try {
        const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
        setGrados(responseGrados.data);
      } catch (error) {
        console.log(error);
      }
    };
    getGrados();
  }, [Id_Docente]);

  const handleChangeDDLGrados = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : null;

    setRestoreTema({
      ...restoreTema,
      [name]: value,
    })
  };


  useEffect(() => {
    const getTemas = async () => {
      try {
        if (restoreTema.IdGrado !== null) {
          const responseTemas = await api.get(`${urlTemasDocentes}?IdDocente=${Id_Docente}&IdGrado=${restoreTema.IdGrado}`);
          setTemas(responseTemas.data);
        }
        else {
          setTemas([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTemas();
  }, [restoreTema, Id_Docente]);

  const handleChangeDDLTemas = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : null;

    setRestoreTema({
      ...restoreTema,
      [name]: value,
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const responseLista = await api.post(
        `${urlListaResultados}`,
        restoreTema
      );
      setResultadosEstudiantes(responseLista.data);
    }
    catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-[#EDE7F6]'>

      <div className='px-4 md:px-10 lg:px-20 mb-5'>
        <h2 className='font-comforter text-center py-5 text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Avance de Alumnos</h2>
        <form onSubmit={handleSubmit}>
          <div className=''>
            <label className="text-base font-medium">Grado: </label>
            <Select
              options={grados}
              className="w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded"
              classNamePrefix="select"
              isSearchable={false} // Para deshabilitar la búsqueda
              name='IdGrado'
              placeholder="Seleccione un Grado"
              onChange={handleChangeDDLGrados}
              value={grados.find(grado => grado.value === restoreTema.IdGrado) || null}
            />

          </div>
          <div className=''>
            <label className="text-base font-medium">Tema: </label>
            <Select
              options={temas}
              className="w-full mt-2 mb-4 text-sm sm:text-base font-medium border-2 border-indigo-500 rounded"
              classNamePrefix="select"
              isSearchable={false} // Para deshabilitar la búsqueda
              name='IdTema'
              placeholder="Seleccione un Tema"
              onChange={handleChangeDDLTemas}
              value={temas.find(tema => tema.value === restoreTema.IdTema) || null}
            />
          </div>
          <div className="mt-4 w-full">
            <button
              className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500"
              type="submit"
            >
              Buscar Lecciones
            </button>
          </div>
        </form>
      </div>



      {
        resultadosEstudiantes.length === 0 ?
          (
            <div className='bg-indigo-100 w-full'>
              <h4 className='text-center rounded-2xl py-4'>
                Seleccione el grado y el tema para ver los resultados de los estudiantes correspondientes.
              </h4>
            </div>
          ) :
          (

            <div className='h-auto my-2 mx-1 px-1 md:px-10 lg:px-20 bg-indigo-200 py-2'>
              <div className='mb-4'>
                <h2 className='text-center text-xl font-comforter'>
                  {resultadosEstudiantes[0].asignaciones[0].id_leccion_leccione.id_tema_tema.nombre_tema && resultadosEstudiantes[0].asignaciones[0].id_leccion_leccione.id_tema_tema.nombre_tema}
                </h2>
              </div>

              {
                Array.isArray(resultadosEstudiantes) && resultadosEstudiantes.map((estudiante) => (
                  <div key={estudiante.codigo_estudiante} className='bg-sky-300 rounded-md mt-2'>
                    <div className={`text-sm flex items-center justify-between cursor-pointer hover:bg-indigo-400 rounded-md ${openStudent === estudiante.codigo_estudiante && "bg-indigo-400"}`} onClick={() => setOpenStudent(openStudent === estudiante.codigo_estudiante ? null : estudiante.codigo_estudiante)}>
                      <div className={`w-full py-3 flex hover:text-white ${openStudent === estudiante.codigo_estudiante ? "text-white" : ""}`}>
                        <div className='w-3/4 flex'>
                          <span className="w-1/4 flex justify-center text-2xl">
                            <PiStudent />
                          </span>
                          <span className="w-3/4 text-center text-base font-medium">
                            {estudiante.Primer_nombre} {estudiante.primer_apellido}
                          </span>
                        </div>
                        <div className='w-1/4 flex justify-center'>
                          <span className="flex items-center text-2xl">
                            <BsClipboardCheckFill />
                          </span>
                          <span className="text-base font-medium flex-1">
                            {/* {resultadosEstudiantes[0].resultados[0].id_leccion_leccione.id_tema_tema.nombre_tema && resultadosEstudiantes[0].resultados[0].id_leccion_leccione.id_tema_tema.temas_x_grados[0].id_grado_grado.nombre_grado} */}
                          </span>
                        </div>
                      </div>
                      <div>
                        <BsChevronDown className={`${openStudent === estudiante.codigo_estudiante ? "" : "rotate-180"} mr-5 rounded-md bg-white text-3xl p-2`} />
                      </div>
                    </div>

                    <ul className={`${openStudent === estudiante.codigo_estudiante ? "" : "hidden"}`}>
                      {
                        Array.isArray(estudiante.asignaciones) && estudiante.asignaciones.map((asignacion, i) => (
                          <div key={i} className='px-2 py-2'>
                            <li className='bg-green-200 border border-green-500 rounded-md flex flex-col items-center justify-center w-full'>
                              <div className='flex flex-col w-full'>
                                <div className='text-center pb-0 md:pb-4'>
                                  <h2 className='font-medium'>Lección</h2>
                                  <h3>{asignacion.id_leccion_leccione.nombre_leccion}</h3>
                                </div>
                                <div className="flex flex-col md:flex-row text-center w-full">
                                  <div className='flex flex-col w-full md:w-1/2'>
                                    <h2 className='font-medium'>Puntaje</h2>
                                    <h4>{asignacion.resultados[0].puntaje}/{asignacion.id_leccion_leccione.cantidad_preguntas}</h4>
                                  </div>
                                  <div className='flex flex-col w-full md:w-1/2'>
                                    <h2 className='font-medium'>Tiempo Transcurrido</h2>
                                    <h4>{asignacion.resultados[0].tiempo_transcurrido}</h4>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                        ))
                      }
                    </ul>


                  </div>

                ))
              }

              {/* {
              resultadosEstudiantes.map((estudiante) => (
                <div key={estudiante.codigo_estudiante}>
                  <div>Primer Nombre: {estudiante.Primer_nombre}</div>
                  <div>Primer Apellido: {estudiante.primer_apellido}</div>
                  <h1>Resultados</h1>
                  <ul>
                    {
                      estudiante.resultados.map((resultado, i) => (
                        <li key={i}>
                          <p>puntaje: {resultado.puntaje}</p>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))
            } */}



            </div>

          )
      }





    </div>
  )
}

export default HomeTeacher
