import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import Select from 'react-select';
import { urlCreateTemas, urlGradosDocentes, urlListaTemasDocentes } from '../../../helpers/endpoints';
import api from '../../../helpers/Api';
import { useLocation } from 'react-router-dom';


function CreateTopics() {
    const location = useLocation();

    const user_D = JSON.parse(localStorage.getItem("user")) || null;

    const Id_Docente = user_D.id_usuario || location?.state?.codigoUsuario;


    const [temas, setTemas] = useState([]);
    const [grados, setGrados] = useState([]);
    const [newTema, setNewTema] = useState({
        NombreTema: "",
        IdGrado: null,
        IdDocente: Id_Docente,
    });

    const [missingFields, setMissingFields] = useState([]);

    const handleChangeTema = (e) => {
        const { name, value } = e.target;

        setNewTema({
            ...newTema,
            [name]: value,
        })

    };

    useEffect(() => {
        const getGrados = async () => {
            try {
                const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
                setGrados(responseGrados.data);
            } catch (error) {
                console.log(error);
            }
        };
        getGrados();
    }, [Id_Docente]);

    const handleChangeDDL = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setNewTema({
            ...newTema,
            [name]: value,
        })
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const missingFieldsArray = [];
        if (!newTema.NombreTema) missingFieldsArray.push('NombreTema');
        if (!newTema.IdGrado) missingFieldsArray.push('IdGrado');
        if (!newTema.IdDocente) missingFieldsArray.push('IdDocente');

        setMissingFields(missingFieldsArray);

        //Validación: Verifica si alguno de los campos obligatorios está vacío
        // if (!newTema.NombreTema || !newTema.IdGrado || !newTema.IdDocente) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
        //     });
        //     return; // Sale de la función si la validación falla
        // }

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return; // Sale de la función si la validación falla
        }


        try {
            const response = await api.post(`${urlCreateTemas}`, newTema);

            const updateResponse = await api.get(`${urlListaTemasDocentes}?IdDocente=${Id_Docente}`);
            setTemas(updateResponse.data);

            Swal.fire({
                icon: 'success',
                title: 'Agregado',
                text: response.data.msg,
            });

            setNewTema({
                NombreTema: "",
                IdGrado: null,
                IdDocente: Id_Docente,
            });
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.error,
            });
        }
    };


    useEffect(() => {
        const getTemas = async () => {
            try {
                const response = await api.get(`${urlListaTemasDocentes}?IdDocente=${Id_Docente}`);
                setTemas(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getTemas();
    }, [Id_Docente]);

    return (

        <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-[#EDE7F6]'>
            <div className='flex justify-center py-5'>
                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Crear Temas</h1>
            </div>
            <div className="px-4 md:px-10 lg:px-20 my-5">
                <form onSubmit={handleSubmit}>
                    <div className='mb-4'>
                        <label className="text-base font-medium">Nombre del Tema: </label>
                        <input
                            className={`w-full border-2 border-indigo-500 rounded p-2 mt-2 ${missingFields.includes('NombreTema') ? 'border-red-500' : ''
                                }`}
                            type="text"
                            name="NombreTema"
                            placeholder="Ingrese el Nombre del Tema"
                            onChange={handleChangeTema}
                            value={newTema.NombreTema}
                        />
                    </div>

                    <div className=''>
                        <label className="text-base font-medium">Grado: </label>
                        <Select
                            options={grados}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded ${missingFields.includes('IdGrado') ? 'border-red-500' : ''
                                }`}
                            classNamePrefix="select"
                            isSearchable={false} // Para deshabilitar la búsqueda
                            name='IdGrado'
                            placeholder="Seleccione un Grado"
                            onChange={handleChangeDDL}
                            value={grados.find(grado => grado.value === newTema.IdGrado) || null}
                        />
                    </div>

                    <div className="mt-4 w-full">
                        <button
                            className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500"
                            type="submit"
                        >
                            Ingresar Tema
                        </button>
                    </div>
                </form>

            </div>


            <div className='px-4 md:px-10 lg:px-20 mt-10'>
                <div className='border-2 border-gray-400'>
                    <table className="bg-gray-100 min-w-full">
                        <thead className='bg-gray-200'>
                            <tr className=''>
                                <th className="py-2 px-4 border-b">
                                    <h2>
                                        Grado
                                    </h2>
                                </th>
                                <th className="py-2 px-4 border-b">
                                    <h2>
                                        Tema
                                    </h2>
                                </th>
                                <th className="py-2 px-4 border-b">
                                    Estado
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>

                            {

                                Array.isArray(temas) && temas.map((tema) => (
                                    <tr key={tema.id_tema}>
                                        <td className='py-2 px-4 border-b'>
                                            <h3 className='w-full flex justify-center'>
                                                {tema.temas_x_grados[0].id_grado_grado.nombre_grado}
                                            </h3>
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <h3 className='w-full flex justify-center'>
                                                {tema.nombre_tema}
                                            </h3>
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <h3 className='flex justify-center bg-green-100 rounded-xl text-emerald-600'>
                                                Activo
                                            </h3>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    )
}

export default CreateTopics