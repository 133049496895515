import React from 'react'
import { Navigate, Route, Router, Routes } from 'react-router-dom'
// import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';


import ProtectedRoutesTeacher from './ProtectedRoutesTeacher'
import ProtectedRoutesStudent from './ProtectedRoutesStudent'
import ProtectedRoute from './ProtectedRoute'
import LoginPage from '../pages/Others/LoginPage'
import { AuthProvider } from '../helpers/authContext'
import PublicRoutes from './PublicRoutes'

export const AppRouter = () => {

  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path='/' element={
            <PublicRoutes>
              <LoginPage />
            </PublicRoutes>
          } />

          <Route
            path='/Estudiantes/*'
            element={
              <ProtectedRoute role={2} >
                <ProtectedRoutesStudent />
              </ProtectedRoute>
            }
          >
          </Route>

          <Route
            path='/Docentes/*'
            element={
              <ProtectedRoute role={1} >
                <ProtectedRoutesTeacher />
              </ProtectedRoute>
            }
          >
          </Route>

          <Route path='*' element={<Navigate to="/" />}></Route>
        </Routes>
      </AuthProvider>
    </>
  )
}
