import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../helpers/Api';
import { urlInformacionAdicional } from '../../../helpers/endpoints';

//import { motion } from 'framer-motion';

import { FaEarlybirds } from 'react-icons/fa';
import { CiHome } from "react-icons/ci";
import { GiElephantHead, GiOrange } from "react-icons/gi";
import { TbPointerQuestion } from "react-icons/tb";
import { IoIosArrowBack } from 'react-icons/io';

const NumeroInfo = require.context("../../../assets/images/Resultados", true);

const BackgroundPattern = () => (

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
        className="absolute inset-0 w-full h-full group-hover:scale-110 transition-transform delay-300 duration-200"
    >
        <circle
            cx="190"
            cy="210"
            r="100"
            fill="none"
            stroke="#EEB6F2"
            //#FF82CB
            strokeWidth="50"
        />
    </svg>


);






const InformationAdditional = () => {
    const location = useLocation();

    const Id_Estudiante = location?.state?.LDatos?.IdEstudiante || "";
    const Id_Grado = location?.state?.LDatos?.IdGrado || "";
    const Id_Tema = location?.state?.LDatos?.IdTema || "";
    const Id_Leccion = location?.state?.LDatos?.IdLeccion || "";
    const Id_TipoL = location?.state?.LDatos.IdTipoL || "";
    const Tiempo_Anterior = location?.state?.LDatos?.Tiempo || "00:00:00";
    const Puntaje_Anterior = location?.state?.LDatos?.PuntajeL || "";
    const Total_Preguntas = location?.state?.LDatos?.Total || "";
    const Random_Number = location?.state?.LDatos?.RandomNumber || "";

    const [hoveredMap, setHoveredMap] = useState({});
    const [informaciones, setInformaciones] = useState([]);
    const [iconMap, setIconMap] = useState([]);
    const [hoverOut, setHoverOut] = useState(false);

    const [startTimeUso, setStartTimeUso] = useState([]);

    useEffect(() => {
        setStartTimeUso(new Date().toISOString());
    }, []);

    useEffect(() => {
        const getInformacion = async () => {
            try {
                const response = await api.get(`${urlInformacionAdicional}?IdEstudiante=${Id_Estudiante}&IdGrado=${Id_Grado}&IdTema=${Id_Tema}&IdLeccion=${Id_Leccion}`);
                setInformaciones(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        getInformacion();
    }, [Id_Estudiante, Id_Grado, Id_Tema, Id_Leccion]);



    const navigate = useNavigate();


    return (
        <div className="h-full mx-auto max-w-2xl px-2 py-5 md:px-10 lg:max-w-7xl bg-[#EDE7F6]">
            <div className="w-10" onMouseEnter={() => setHoverOut(true)} onMouseLeave={() => { setHoverOut(false) }}>
                <button className="flex justify-center items-center font-bols border border-gray-500 p-1 rounded-lg transition-all text-gray-500 hover:p-2 hover:bg-red-400 hover:border-white hover:text-white" onClick={() => navigate("/Estudiantes/LeccionesAdicionales", { state: { datos: { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: Id_Tema } } })}>
                    <IoIosArrowBack className='font-bold text-lg' />
                    <h3 className={`text-sm ${hoverOut === false ? 'hidden' : 'block'}`}>Atrás</h3>
                </button>
            </div>
            <div className='flex flex-col md:flex-row md:justify-between'>
                <div className="w-full md:w-2/5 p-2">
                    <div className='bg-indigo-500 rounded-t-lg text-white text-center'>
                        <h2 className='text-sm font-medium'>Jarub´risi ut hoonal roksi</h2>
                    </div>
                    <div className='border-2 border-indigo-500 rounded-b-lg '>
                        <div className='relative w-full h-9 rounded-lg bg-orange-100'>
                            <div className="bg-orange-400 rounded-lg h-full w-full" style={{ width: `${(Puntaje_Anterior / Total_Preguntas) * 100}%` }}>
                            </div>
                            <div className="absolute inset-y-0 inset-x-0">
                                <div className="flex items-center justify-center h-full w-full">
                                    <h3 className="text-white text-lg font-medium">{Puntaje_Anterior}/{Total_Preguntas}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full md:w-2/5 p-2 text-center'>
                    <div className='bg-indigo-500 rounded-t-lg text-white'>
                        <h2 className='text-sm font-medium'>Jarub´risi ut hoonal roksi</h2>
                    </div>
                    <div className='border-2 border-indigo-500 rounded-b-lg h-10 flex items-center bg-gray-200'>
                        <div className='flex flex-row justify-between w-full'>
                            <div className="flex flex-col justify-center items-center w-full">
                                <h4 className='text-xs font-medium'>{Tiempo_Anterior.slice(0, 2)}</h4>
                                <h4 className='text-xs font-medium'>Hoonal</h4>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h4 className='text-xs font-medium'>:</h4>
                            </div>
                            <div className="flex flex-col justify-center items-center w-full">
                                <h4 className='text-xs font-medium'>{Tiempo_Anterior.slice(3, 5)}</h4>
                                <h4 className='text-xs font-medium'>Miin</h4>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h4 className='text-xs font-medium'>:</h4>
                            </div>
                            <div className="flex flex-col justify-center items-center w-full">
                                <h4 className='text-xs font-medium'>{Tiempo_Anterior.slice(-2)}</h4>
                                <h4 className='text-xs font-medium'>K´asal</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Título */}
            <div className="text-center mt-4 mb-3 sm:mt-6">
                <h2 className="font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg">
                    {informaciones.nombre_leccion}
                </h2>
            </div>

            {/* Información */}
            <div className="text-gray-800 bg-gradient-to-br from-blue-50 to-sky-100 rounded-xl text-center p-4 mx-2 lg:mt-6 shadow-md">
                <h3 className="text-justify text-sm sm:text-base border-l-4 border-blue-400 pl-3 font-poppins">
                    {informaciones.informacion}
                </h3>
            </div>


            {/* Ejemplos */}
            <div className="flex flex-wrap justify-center sm:justify-around md:justify-around items-start gap-10 my-5 sm:my-8">
                {informaciones.ejemplos &&
                    informaciones.ejemplos.map((informacionn) => (
                        <div
                            key={informacionn.id_ejemplo}
                            className="group relative w-64 h-52 transition-colors duration-500"
                        >
                            {/* Círculo dinámico con efecto visual */}
                            <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-4 bg-gradient-to-br from-pink-200 to-purple-200 w-28 h-28 rounded-full flex justify-center items-center transition-transform duration-700 group-hover:translate-y-28 group-hover:scale-110 shadow-lg shadow-purple-300/50 border-4 border-transparent group-hover:border-white z-10">
                                <img
                                    src={NumeroInfo(`./Information.png`)}
                                    alt={informacionn.ejemplo}
                                    className="object-center object-contain"
                                />
                            </div>

                            {/* Cuadro principal con transición de colores */}
                            <div className="w-full h-full flex flex-col gap-4 rounded-3xl bg-gradient-to-tr from-purple-300 to-indigo-200 group-hover:from-yellow-200 group-hover:to-orange-200 transition-all duration-500 shadow-md shadow-blue-800 group-hover:shadow-lg group-hover:shadow-orange-300 relative overflow-hidden">
                                <BackgroundPattern />
                                <h3 className="h-full opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex justify-center items-end text-gray-700 font-semibold text-sm sm:text-base md:text-xl font-poppins z-10">
                                    {informacionn.ejemplo_traduccion}
                                </h3>
                                <h3 className="h-full opacity-100 group-hover:opacity-0 transition-opacity duration-500 text-center text-gray-500 font-semibold text-sm sm:text-base md:text-xl font-poppins z-10">
                                    {informacionn.ejemplo}
                                </h3>
                            </div>
                        </div>
                    ))}
            </div>




            <div className='flex flex-col mx-2 mb-2 sm:mb-5 sm:py-5 md:pt-10'>
                <div className='px-2'>
                    <h4 className='text-center text-sm sm:text-base'>
                        {/* {informaciones.nombre_leccion} */}
                        Chi sa´ li k´anjel wank ob´ li patz´om, ut aajel ru naq oxib´ taasume chi chaab´il, re naq tatnumeq´ sa´ jalan chik li k´anjel.
                    </h4>
                </div>
                <div className='w-full flex justify-center my-4 lg:mt-10'>
                    <button className='w-full md:w-4/6 sm:h-10 md:h-12 rounded-lg bg-gradient-to-r from-indigo-500 to-blue-800 border-blue-800 hover:from-blue-800 hover:to-blue-800' onClick={() => navigate("/Estudiantes/QuizAdicional", { state: { PDatos: { IdEstudiante: Id_Estudiante, IdGrado: Id_Grado, IdTema: Id_Tema, IdLeccion: Id_Leccion, IdTipoL: Id_TipoL, QTiempo: Tiempo_Anterior, QPuntaje: Puntaje_Anterior, QTotal: Total_Preguntas, TiempoUso: startTimeUso, RNumber: Random_Number } } })}>
                        <h3 className='text-white text-xl font-bold'>
                            Comenzar
                        </h3>
                    </button>
                </div>
            </div>


        </div>




    )
}

export default InformationAdditional