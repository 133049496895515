import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import api from '../../../helpers/Api';
import { urlCreateLecciones, urlGradosDocentes, urlTemasDocentesSimples, urlTiposLecciones } from '../../../helpers/endpoints';

function CreateLessons() {
    const location = useLocation();

    const user_D = JSON.parse(localStorage.getItem("user")) || null;

    const Id_Docente = user_D.id_usuario || location?.state?.codigoUsuario;

    const [grados, setGrados] = useState([]);
    const [temas, setTemas] = useState([]);
    const [tipoLeccion, setTipoLeccion] = useState([]);


    const [leccionData, setLeccionData] = useState({
        NombreLeccion: '',
        IdGrado: null,
        IdTema: null,
        IdTipoLeccion: null,
        IdDocente: Id_Docente,
        Informacion: '',
        EjemplosU: [
            {
                EjemploEsp: '',
                EjemploTrd: ''
            }
        ],
    });

    const [missingFields, setMissingFields] = useState([]);

    //Drop down list para grados
    useEffect(() => {
        const getGrados = async () => {
            try {
                const responseGrados = await api.get(`${urlGradosDocentes}?IdDocente=${Id_Docente}`);
                setGrados(responseGrados.data);
            } catch (error) {
                console.log(error);
            }
        };
        getGrados();
    }, [Id_Docente]);

    const handleChangeDDLGrados = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setLeccionData({
            ...leccionData,
            [name]: value,
        });
    };


    //Drop down list para temas
    useEffect(() => {
        const getTemas = async () => {
            try {
                if (leccionData.IdGrado !== null) {
                    const responseTemas = await api.get(`${urlTemasDocentesSimples}?IdDocente=${Id_Docente}&IdGrado=${leccionData.IdGrado}`);
                    setTemas(responseTemas.data);
                }
                else {
                    setTemas([]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getTemas();
    }, [leccionData, Id_Docente]);

    const handleChangeDDLTemas = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;
        //setSelectedTemaId(selectedOption ? selectedOption.value : null);

        setLeccionData({
            ...leccionData,
            [name]: value,
        });
    };


    //Drop down list para tipo de lecciones
    useEffect(() => {
        const getTiposLecciones = async () => {
            try {
                const responseTiposLecciones = await api.get(`${urlTiposLecciones}`);
                setTipoLeccion(responseTiposLecciones.data);
            } catch (error) {
                console.log(error);
            }
        };
        getTiposLecciones();
    }, []);

    const handleChangeDDLTiposLecciones = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : null;

        setLeccionData({
            ...leccionData,
            [name]: value,
        });
    };

    //Input para la nueva lección
    const handleChangeLeccion = (e) => {
        const { name, value } = e.target;

        setLeccionData({
            ...leccionData,
            [name]: value
        });
    };

    //Input para la información de la lección
    const handleChangeInformacion = (e) => {
        const { name, value } = e.target;

        setLeccionData({
            ...leccionData,
            [name]: value
        });
    };




    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedEjemplos = [...leccionData.EjemplosU];
        updatedEjemplos[index][name] = value;
        setLeccionData({
            ...leccionData,
            EjemplosU: updatedEjemplos
        });
    };

    const handleAddExample = () => {
        setLeccionData({
            ...leccionData,
            EjemplosU: [...leccionData.EjemplosU, { EjemploEsp: '', EjemploTrd: '' }]
        });
    };

    const handleRemoveExample = (index) => {
        const updatedEjemplos = [...leccionData.EjemplosU];

        if (updatedEjemplos.length > 1) {

            updatedEjemplos.splice(index, 1);
            setLeccionData({
                ...leccionData,
                EjemplosU: updatedEjemplos
            });
        }
        else {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                icon: 'warning',
                title: 'Debe haber al menos un ejemplo con su traducción.',
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
        }

    };




    const handleSubmit = async (e) => {
        e.preventDefault();

        const missingFieldsArray = [];
        if (!leccionData.NombreLeccion) missingFieldsArray.push('NombreLeccion');
        if (!leccionData.IdGrado) missingFieldsArray.push('IdGrado');
        if (!leccionData.IdTema) missingFieldsArray.push('IdTema');
        if (!leccionData.IdTipoLeccion) missingFieldsArray.push('IdTipoLeccion');

        if (!leccionData.IdDocente) missingFieldsArray.push('IdDocente');
        if (!leccionData.Informacion) missingFieldsArray.push('Informacion');
        if (!leccionData.EjemplosU.every((example) => example.EjemploEsp)) missingFieldsArray.push('EjemploEsp');
        if (!leccionData.EjemplosU.every((examplee) => examplee.EjemploTrd)) missingFieldsArray.push('EjemploTrd');

        setMissingFields(missingFieldsArray);

        if (missingFieldsArray.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puede dejar ningún campo vacío. Por favor, complete todos los campos.',
            });
            return; // Sale de la función si la validación falla
        }


        try {
            const responseLeccion = await api.post(`${urlCreateLecciones}`, leccionData);

            Swal.fire({
                icon: 'success',
                title: 'Agregado',
                text: responseLeccion.data.msg,
            });


            setLeccionData({
                NombreLeccion: "",
                IdGrado: null,
                IdTema: null,
                IdTipoLeccion: null,
                IdDocente: Id_Docente,
                Informacion: '',
                EjemplosU: [
                    {
                        EjemploEsp: '',
                        EjemploTrd: ''
                    }
                ],
            });

        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.error,
            });
        }

    };

    return (
        <div className='h-full mx-auto max-w-2xl px-2 md:px-10 lg:max-w-7xl bg-[#EDE7F6]'>
            <div className='flex justify-center py-5'>
                <h1 className='font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg'>Crear Lecciones</h1>
            </div>

            <div className='px-4 md:px-10 lg:px-10 my-5'>
                <form className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6' onSubmit={handleSubmit}>
                    <div className=''>
                        <label className="text-base font-medium">Grado: </label>
                        <Select
                            options={grados}
                            // className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                            //             ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                        ${missingFields.includes('IdGrado') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false} // Para deshabilitar la búsqueda
                            name='IdGrado'
                            placeholder="Seleccione un Grado"
                            onChange={handleChangeDDLGrados}
                            value={grados.find(grado => grado.value === leccionData.IdGrado) || null}
                        />
                    </div>

                    <div className=''>
                        <label className="text-base font-medium">Tema: </label>
                        <Select
                            options={temas}
                            // className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                            //             ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                        ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false} // Para deshabilitar la búsqueda
                            name='IdTema'
                            placeholder="Seleccione un Tema"
                            onChange={handleChangeDDLTemas}
                            value={temas.find(tema => tema.value === leccionData.IdTema) || null}
                        />
                    </div>

                    <div className=''>
                        <label className="text-base font-medium">Tipo de Lección: </label>
                        <Select
                            options={tipoLeccion}
                            // className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                            //             ${missingFields.includes('IdTema') ? 'border-red-500' : ''}`}
                            className={`w-full mt-2 mb-4 border-2 border-indigo-500 rounded
                                        ${missingFields.includes('IdTipoLeccion') ? 'border-red-500' : ''}`}
                            classNamePrefix="select"
                            isSearchable={false} // Para deshabilitar la búsqueda
                            name='IdTipoLeccion'
                            placeholder="Seleccione el Tipo de Lección"
                            onChange={handleChangeDDLTiposLecciones}
                            value={tipoLeccion.find(tipoL => tipoL.value === leccionData.IdTipoLeccion) || null}
                        />
                    </div>

                    <div className='mb-4'>
                        <label className="text-base font-medium">Nombre de la Lección: </label>
                        <input
                            // className={`w-full border-2 border-indigo-500 rounded p-2 mt-2 ${
                            //     missingFields.includes('NombreTema') ? 'border-red-500' : ''
                            // }`}
                            className={`w-full border-2 border-indigo-500 rounded p-2 mt-2
                                            ${missingFields.includes('NombreLeccion') ? 'border-red-500' : ''}`}
                            type="text"
                            name="NombreLeccion"
                            placeholder="Ingrese el Nombre de la Lección"
                            onChange={handleChangeLeccion}
                            value={leccionData.NombreLeccion}
                        />
                    </div>

                    <div className='mb-4 lg:col-span-2'>
                        <label className="text-base font-medium">Información: </label>
                        <textarea
                            // className={`w-full border-2 border-indigo-500 rounded p-2 mt-2 ${
                            //     missingFields.includes('NombreTema') ? 'border-red-500' : ''
                            // }`}
                            className={`w-full border-2 border-indigo-500 rounded p-2 mt-2
                                            ${missingFields.includes('Informacion') ? 'border-red-500' : ''}`}
                            type="text"
                            name="Informacion"
                            placeholder="Ingrese la Información de la Lección."
                            onChange={handleChangeInformacion}
                            value={leccionData.Informacion}
                        />
                    </div>

                    <div className='mb-10 lg:mb-0 px-2 pt-2 pb-4 lg:px-4 lg:pt-4 lg:pb-6 lg:col-span-2 rounded-xl bg-sky-300'>
                        <div className="mb-2">
                            <label className='text-base font-medium'>Ejemplos: </label>
                        </div>
                        {leccionData && leccionData.EjemplosU.map((example, index) => (
                            <div key={index} className='mb-4 px-1 py-2 lg:p-4 grid grid-cols-1 gap-4 lg:grid-cols-10 lg:gap-6 rounded-xl bg-orange-200'>
                                <input
                                    className={`w-full h-full mt-2 px-2 lg:mt-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                                ${missingFields.includes('EjemploEsp') ? 'border-red-500' : ''}`}
                                    type="text"
                                    name="EjemploEsp"
                                    placeholder="Ingrese el ejemplo en Español"
                                    value={example.EjemploEsp}
                                    onChange={(e) => handleChange(e, index)}
                                />
                                <input
                                    className={`w-full h-full mb-2 px-2 lg:mb-0 border-2 lg:col-span-4 border-indigo-500 rounded
                                                ${missingFields.includes('EjemploTrd') ? 'border-red-500' : ''}`}
                                    type="text"
                                    name="EjemploTrd"
                                    placeholder="Ingrese el ejemplo en Q’eqchi’"
                                    value={example.EjemploTrd}
                                    onChange={(e) => handleChange(e, index)}
                                />
                                <button className='w-full lg:col-span-2 active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-1 rounded-xl bg-red-500 text-white text-lg font-bold px-4 hover:bg-red-600' type="button" onClick={() => handleRemoveExample(index)}>
                                    Eliminar
                                </button>
                            </div>
                        ))}
                        <button className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-1 rounded-xl bg-green-500 text-white text-lg font-bold px-4 hover:bg-green-600' type="button" onClick={handleAddExample}>
                            Agregar Ejemplo
                        </button>
                    </div>

                    <button type="submit" className='w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl lg:col-span-2 bg-gradient-to-r from-blue-400 to-indigo-400 text-white text-lg font-bold px-4 hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-500'>Guardar Lección</button>

                </form>

            </div>
        </div>
    )
}

export default CreateLessons