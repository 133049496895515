import { useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import api from "../../../helpers/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { urlTemasAdicionales } from "../../../helpers/endpoints";



const NumeroTema = require.context("../../../assets/images/Lecciones_A", true);

function generateUniqueRandomNumbers(count, max) {
    const numbers = [];

    while (numbers.length < count) {
        const randomNumber = Math.floor(Math.random() * max);
        if (!numbers.includes(randomNumber)) {
            numbers.push(randomNumber);
        }
    }
    return numbers;
}

function HomeStudentAdditional() {

    const location = useLocation();


    const user_D = JSON.parse(localStorage.getItem("user")) || null;

    const Id_Estudiante = user_D?.id_usuario || "";

    const [temas, setTemas] = useState([]); // Usamos el hook useState para manejar el estado de la lista

    const randomNumbers = useMemo(() => generateUniqueRandomNumbers(temas.length, 15), [temas.length]);

    useEffect(() => {
        // Usamos el hook useEffect para ejecutar el request cuando el componente se monta
        // Declaramos una función asíncrona que hace el request
        const getTemas = async () => {
            try {
                // Usamos await para esperar la respuesta del request
                const response = await api.get(`${urlTemasAdicionales}?CodigoEstudiante=${Id_Estudiante}`);
                // Actualizamos el estado con la lista que recibimos
                setTemas(response.data);
            } catch (error) {
                // Manejamos el error
                console.log(error);
            }
        };
        // Invocamos la función asíncrona
        getTemas();
    }, [Id_Estudiante]); // El array vacío indica que solo se ejecuta una vez

    /* En la versión 6 de react-router-dom, el componente Link ya no acepta un objeto como prop to,
    sino que solo acepta una cadena que representa la ruta. Además, ya no se puede pasar datos arbitrarios
    como state a través del prop to, sino que se debe usar el hook useNavigate para navegar programáticamente y el hook useLocation para acceder al state
    */
    const navigate = useNavigate();

    return (
        <div className="bg-gray-200 h-full">
            <div className="mx-auto max-w-2xl h-full px-4 py-5 sm:px-6 sm:py-0 lg:max-w-7xl lg:px-8 bg-[#EDE7F6]">
                <div className="flex justify-center py-2 border-b-4 border-b-orange-500 ">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "spring",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="py-1 text-5xl font-extrabold mx-auto">
                        <h2 className="font-comforter text-lg sm:text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-800 to-purple-700 drop-shadow-lg">
                            Xtz´aqob´ Nawom
                        </h2>
                    </motion.div>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 xl:gap-x-8">
                    {temas && temas.map((tema, index) => {

                        const randomIndex = randomNumbers[index];
                        return (
                            <div
                                key={tema.id_tema}
                                className="flex flex-col justify-between bg-indigo-700 rounded-3xl px-2 hover:bg-orange-500 hover:text-white transition duration-300 cursor-pointer transform hover:scale-105"
                            >
                                <div>
                                    <div className="pt-6 text-white text-center">
                                        <h2 className="text-sm sm:text-base md:text-2xl font-medium">
                                            Xkab´ Tzolom {index + 1}
                                        </h2>
                                    </div>
                                    <div className="pb-6 text-yellow-300 text-center">
                                        <h5 className="text-sm sm:text-base md:text-2xl font-medium">
                                            {tema.nombre_tema}
                                        </h5>
                                    </div>
                                </div>

                                <div>
                                    <div className="aspect-h-1 aspect-w-1 w-full flex justify-center overflow-hidden rounded-3xl bg-red-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                                        <img
                                            src={NumeroTema(`./Books_${randomIndex}.png`)}
                                            alt={tema.nombre_tema}
                                            className="object-center object-contain"
                                        />
                                    </div>

                                    <div className="mt-4 flex flex-col text-center text-white">
                                        <h3 className="text-sm sm:text-base md:text-2xl font-medium">
                                            <div className="text-sm sm:text-base md:text-2xl" onClick={() => navigate("/Estudiantes/LeccionesAdicionales", { state: { datos: { IdEstudiante: Id_Estudiante, IdGrado: tema.lecciones[0].asignaciones[0].id_grado, IdTema: tema.id_tema } } })}>
                                                <span aria-hidden="true" className="absolute inset-0" />
                                                Raq´al xketom:
                                            </div>
                                        </h3>
                                    </div>

                                    <div className="container mx-auto p-4">
                                        <div className="flex items-center justify-center">
                                            <div className="relative w-full h-full bg-gray-200 rounded-lg">
                                                <div className="bg-yellow-300 rounded-lg h-10" style={{ width: `${(tema.lecciones_aprobadas / tema.total_lecciones) * 100}%` }}>
                                                </div>
                                                <div className="absolute inset-y-0 inset-x-0">
                                                    <div className="flex items-center justify-center h-full w-full">
                                                        <h3 className="text-orange-500 text-sm sm:text-base md:text-2xl font-medium">{tema.lecciones_aprobadas}/{tema.total_lecciones}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        )

                    })}
                </div>


            </div>
        </div>


    )
}

export default HomeStudentAdditional