import React from 'react'
import { Route, Routes } from 'react-router-dom'

import NavbarStudent from '../components/Student/NavbarStudent'
import StudentHomePage from '../pages/Student/Default/StudentHomePage'
import LectionHomePage from '../pages/Student/Default/LectionHomePage'
import InformationPage from '../pages/Student/Default/InformationPage'
import QuizPage from '../pages/Student/Default/QuizPage/QuizPage'
import StudentHomePageAdditional from '../pages/Student/Additional/StudentHomePageAdditional';
import LectionHomePageAdditional from '../pages/Student/Additional/LectionHomePageAdditional';
import InformationPageAdditional from '../pages/Student/Additional/InformationPageAdditional';
import QuizPageAdditional from '../pages/Student/Additional/QuizPageAdditional';




const ProtectedRoutesStudent = () => {

    return (
        <Routes>
            <Route path='/' element={<NavbarStudent />}>
                <Route index element={<StudentHomePage />} />
                <Route path="Lecciones" element={<LectionHomePage />} />
                <Route path="Informacion" element={<InformationPage />} />
                <Route path="Quiz" element={<QuizPage />} />
                <Route path="TemasAdicionales" element={<StudentHomePageAdditional />} />
                <Route path="LeccionesAdicionales" element={<LectionHomePageAdditional />} />
                <Route path="InformacionAdicional" element={<InformationPageAdditional />} />
                <Route path="QuizAdicional" element={<QuizPageAdditional />} />
            </Route>
        </Routes>
    );

};

export default ProtectedRoutesStudent