import axios from "axios";
import { Navigate } from "react-router-dom";



const api = axios.create({
    baseURL: "https://www.sacsichiyo.com/api/",
    // baseURL: "http://localhost:5173/api/",
    withCredentials: true
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((promise) => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });
    failedQueue = [];
};

api.interceptors.response.use(
    (response) => response, // Si no hay errores, simplemente retorna la respuesta
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                // Si ya se está refrescando el token, añade la solicitud a la cola
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] = `Bearer ${token}`;
                        console.log("tOKEEEEEEN:", token);
                        return api(originalRequest);
                    })
                    .catch((err) => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                // Solicita nuevos tokens
                const { data } = await api.post('/auth/renovation', {}, { withCredentials: true });
                isRefreshing = false;

                // Procesa las solicitudes en la cola
                processQueue(null, data.newAccessToken);

                // Reintenta la solicitud original con el nuevo token
                originalRequest.headers['Authorization'] = `Bearer ${data.newAccessToken}`;
                return api(originalRequest);
            } catch (err) {
                isRefreshing = false;
                processQueue(err, null);
                console.log("error");
                // Opcional: Limpia la información del usuario al fallar la renovación
                // window.localStorage.removeItem('user');
                // window.location.href = "/";
                localStorage.removeItem('user');
                window.location.href = "/";
                return Promise.reject(err);
            }
        }
        localStorage.removeItem('user');
        window.location.href = "/";
        return Promise.reject(error);
    }
);


export default api;